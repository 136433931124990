import React from "react";
import "../css/logindetails.css";

function LoginDetailsPage() {
  return (
    <div className="container loginDetails-main">
      <h3 className="text-center mt-5 loginDetails-main-title" >Who can <span>Become Our Partner</span> </h3>
      <div className="row my-5">
        <div className="col-md-2 d-flex justify-content-center">
          <div className="loginDetails">
            <h3>CA</h3>
          </div>
        </div>

        <div className="col-md-2 d-flex justify-content-center">
          <div className="loginDetails">
            <h3 className="m-0" >Investment Banker</h3>
          </div>
        </div>

        <div className="col-md-2 d-flex justify-content-center">
          <div className="loginDetails">
            <h3 className="m-0" >Fractional CFO</h3>
          </div>
        </div>

        <div className="col-md-2 d-flex justify-content-center">
          <div className="loginDetails">
            <h3 className="m-0" >Consultant</h3>
          </div>
        </div>

        <div className="col-md-2 d-flex justify-content-center">
          <div className="loginDetails">
            <h3 className="m-0" >Advisor</h3>
          </div>
        </div>

        <div className="col-md-2 d-flex justify-content-center">
          <div className="loginDetails">
            <h3 className="m-0" >DSA</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginDetailsPage;
