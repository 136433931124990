import React from "react";
import axios from "axios";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillTwitterSquare } from "react-icons/ai";
import {AiFillLinkedin} from 'react-icons/ai'
import "../css/topbar.css";

const TopBar = () => {
  return (
    <>
      <div className="topbar">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-md-11">
              <div className="text-start">
                 
              </div>
            </div>
            <div className="col-md-1">
              <div className="d-flex justify-content-around align-items-center social-icon-topbar ">
                <a href="https://www.linkedin.com/company/vinest-fund/" target="blank"> < AiFillLinkedin className="social-icons"  /></a>
                <a href="https://instagram.com/vinestfund?igshid=OGQ5ZDc2ODk2ZA==" target="blank" ><AiFillInstagram className="social-icons" /></a>
                
                <a href="" target="blank"><AiFillTwitterSquare className="social-icons"  /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
