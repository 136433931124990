import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import { setUserData } from "../Redux/actions";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { connect } from "react-redux";
import "owl.carousel/dist/assets/owl.carousel.css";
import GoogleLogin from "./GoogleLogin";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer, toast } from "react-toastify";
import { registrationApi, QueryForInvestorApi } from "../Apis";
import "react-toastify/dist/ReactToastify.css";

import Swal from "sweetalert2";

import "./../css/investorlogin.css";

let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

function InvestorLogin({ userDataRedux }) {
  const navigation = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [isPasswordShow, setisPasswordShow] = useState(false);
  const [isCPasswordShow, setisCPasswordShow] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const googleLogin = () => {
    GoogleLogin(1,navigation, (data) => {
      userDataRedux(data);
    });
  };

  const handleClickRegistration = () => {
    if (name === "" || email === "" || password === "") {
      toast.error("Fields Should Not Be Empty", {
        theme: "colored",
      });
      setEmailError(true);
      setNameError(true);
      setPasswordError(true);
    } else if (password !== cpassword) {
      toast.error("Password and Confirm Password Does Not Match", {
        theme: "colored",
      });
      setPasswordError(true);
    } else if (emailreg.test(email) === false) {
      toast.error("Email Format is not correct it Contain @ & .com", {
        theme: "colored",
      });
      setEmailError(true);
    } else if (passReg.test(password) === false) {
      toast.error(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters",
        {
          theme: "colored",
        }
      );
      setPasswordError(true);
    } else {
      registrationApi(name, email, password, 1, (data) => {
        let Userdata = data?.data?.user;
        if (data.status === "success") {
          Swal.fire({
            title: "Success",
            text: data.message,
            icon: "success",
            confirmButtonColor: "#343C5C",
          });
          if (Userdata.role === 4) {
            navigation("/partner-login");
          } else {
            navigation("/startup-login");
          }
        } else if (data.status === "failed") {
          toast.error(data.message, {
            theme: "colored",
          });
        }
      });
    }
  };

  const handleSubmit =()=>{
    let formdata={
      email:userEmail
    }
    QueryForInvestorApi(formdata, setUserEmail)
  }

  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[])
  return (
    <>
      <ToastContainer />
      <NavBar />
      <div className="coming_soon">
        <img src="/img/logo.png" alt="logo-img" />
          <h2>We are <span>Coming Soon!</span></h2>
          <p>We are almost there! If you want to get notified when the website goes live subscribe to our mailing list!</p>
           <input required type='email' placeholder='Enter Email' value={userEmail} onChange={(e)=>setUserEmail(e.target.value)}  />
           <button onClick={handleSubmit} >Notify Me!</button>
      </div>
      <div className="main-invest d-none">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6 d-flex justify-content-center align-items-center ">
              <div className="investor-top-first-section">
                <h2>
                  Grow your <span>money!</span>
                </h2>
                <p>Fixed income yields with attractive risk adjusted returns</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="investor-top-first-img">
                <img
                  src="https://img.freepik.com/free-vector/hand-drawn-stock-market-concept_52683-76566.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5 pt-5">
          <div className="invest-middle-heading">
            <h3>Invest on your terms</h3>
            <h5>Define your investment goal and risk profile</h5>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="invest-tabs">
                <span>
                  {" "}
                  <img
                    src="https://uploads-ssl.webflow.com/62c3cad34a5b3235b0d518de/62f254597568b4bb077ccb5b_fluent_target-arrow-24-filled.png"
                    alt=""
                  />{" "}
                  Set Target Return
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="invest-tabs">
                <span>
                  {" "}
                  <img
                    src="https://uploads-ssl.webflow.com/62c3cad34a5b3235b0d518de/62fbc2d6c67ba2d4d69e5fd5_bi_bookmark-star.svg"
                    alt=""
                  />{" "}
                  Filter Companies
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="invest-tabs">
                <span>
                  {" "}
                  <img
                    src="https://uploads-ssl.webflow.com/62c3cad34a5b3235b0d518de/62e92bbf13efa624a1c5e7c5_emojione_money-bag.svg"
                    alt="img"
                  />{" "}
                  Invest On Your Terms
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-5 shadow bg-white py-5 white-box-invest">
            <div className="col-md-6">
              <div className="invest-second-last-first-section">
                <img
                  src="https://uploads-ssl.webflow.com/62c3cad34a5b3235b0d518de/63037d166e4dd6fa6822acaa_Filter%20companies-p-500.webp"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="invest-second-last-second-section">
                <h2>2. Filter Companies</h2>
                <h5>
                  Our Simple to use filters will help you select your target
                  company profile.
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center ">
              <div className="invest-login-left">
                <h1>Are you ready to grow your money?</h1>
                <button>SIGNUP {"->"}</button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="container">
                <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-10">
                <div className="invest-signup-form">
                  <div className="form">
                    <h1>Signup</h1>
                    <div className="register-input-single mt-2">
                      <label
                        htmlFor="fname"
                        style={{ color: nameError ? "red" : "#000" }}
                      >
                        Name*
                      </label>
                      <input
                        style={{
                          color: nameError ? "red" : "#000",
                          borderColor: nameError ? "red" : "rgb(182, 174, 174)",
                        }}
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="register-input-single mt-2">
                      <label
                        htmlFor="fname"
                        style={{ color: emailError ? "red" : "#000" }}
                      >
                        Email*
                      </label>
                      <input
                        style={{
                          color: emailError ? "red" : "#000",
                          borderColor: emailError
                            ? "red"
                            : "rgb(182, 174, 174)",
                        }}
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="register-input-single mt-2">
                      <label
                        htmlFor="password"
                        style={{ color: passwordError ? "red" : "#000" }}
                      >
                        Password*
                      </label>
                      <input
                        style={{
                          color: passwordError ? "red" : "#000",
                          borderColor: passwordError
                            ? "red"
                            : "rgb(182, 174, 174)",
                        }}
                        type={isPasswordShow ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="********"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {isPasswordShow ? (
                        <AiFillEyeInvisible
                          onClick={() => setisPasswordShow(!isPasswordShow)}
                          className="eye-icons-invest"
                        />
                      ) : (
                        <AiFillEye
                          onClick={() => setisPasswordShow(!isPasswordShow)}
                          className="eye-icons-invest"
                        />
                      )}
                      <p
                        style={{
                          color: passwordError ? "red" : "#000",
                          borderColor: passwordError
                            ? "red"
                            : "rgb(182, 174, 174)",
                        }}
                        className="password-minimum"
                      >
                        Password (minimum 8 characters)
                      </p>
                    </div>

                    <div className="register-input-single mt-2">
                      <label
                        htmlFor="password"
                        style={{ color: passwordError ? "red" : "#000" }}
                      >
                        Confirm Password*
                      </label>
                      <input
                        style={{
                          color: passwordError ? "red" : "#000",
                          borderColor: passwordError
                            ? "red"
                            : "rgb(182, 174, 174)",
                        }}
                        type={isCPasswordShow ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="********"
                        value={cpassword}
                        onChange={(e) => setcPassword(e.target.value)}
                      />
                      {isCPasswordShow ? (
                        <AiFillEyeInvisible
                          onClick={() => setisCPasswordShow(!isCPasswordShow)}
                          className="eye-icons-invest"
                        />
                      ) : (
                        <AiFillEye
                          onClick={() => setisCPasswordShow(!isCPasswordShow)}
                          className="eye-icons-invest"
                        />
                      )}
                    </div>

                    <div className="register-buttons">
                      <div
                        className="google-register-btn"
                        onClick={googleLogin}
                      >
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-9 google-button">
                              <p>Signup with</p>
                            </div>
                            <div className="col-md-3 google-button">
                              <img src="/Icons/google-icon.png" alt="Image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={handleClickRegistration}
                        className="register-btn"
                      >
                        Sign up
                      </button>
                    </div>

                    <p className="dont-account">
                      Already have an account?{" "}
                      <span onClick={() => navigation("/partner-login")}>
                        Login
                      </span>
                    </p>
                  </div>
                </div>
                </div>
                {/* <div className="col-md-2"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    userDataRedux: (parameter) => {
      dispatch(setUserData(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorLogin);
