import React,{useEffect} from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import "./../css/about.css";

function About() {

  useEffect(()=>{
    window.scrollTo({top: 0,behavior: 'smooth'});
  },[])

  return (
    <>
      <NavBar />
      <div className="container">
        <h4 className="text-start">About Us</h4>
        
        <div className="row">
          <div className="col-md-8 about-us-texts">
          <div className="py-3 text-start">
          <h3 className="aboutus-main-heading" >
            <b>Empowering the next wave of business leaders,</b> <span className="aboutus-main-heading-span">your Gateway to
            all your Fund needs</span>
          </h3>
        </div>
            <div className="py-2 text-start">
              <span>
                Today, a considerable gap persists in the financing industry
                wherein, Startups/ MSMEs are unaware of the various funding
                options available for them to meet their financial needs.
              </span>
            </div>
            <div className="py-2 text-start">
              <span>
                Even after hundreds of new age finance options being available,
                startups majorly depend on the banking lending process which
                typically takes more than 2 months. Our aim is to bring down the
                time to raise debt funding from 2 months to less than 15 days.
              </span>
            </div>
            <div className="py-2 text-start">
              <span>
                Vinest is an AI driven matchmaking platform wherein the most
                suitable funding option for your business at the best pricing
                will be a click away.{" "}
              </span>
            </div>

            <div className="py-2 text-start">
              <span>
                Founders will be more focused to grow their business instead of
                investing time to get debt capital.
              </span>
            </div>
            <div className="py-2 text-start">
              <span>
                We want to provide founders the opportunity to explore multiple
                debt financing options under one roof without having to spend a
                lot of time exploring debt options using different platforms.{" "}
              </span>
            </div>
          
          </div>
          <div className="col-md-4">
            <div className="aboutus-image">
              <img
                src="https://consulting.stylemixthemes.com/wp-content/uploads/2017/02/about_video-540x320.jpg"
                alt="image"
              />
            </div>
          </div>
        </div>

        <h3 className="py-3">Who We Are </h3>
        <p>We are a team of passionate individuals on a mission to empower future business leaders by helping them for all kinds of funding requirements through our platform.</p>

        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-md-2 mt-4">
              <span className="founder-text">Founders</span>
            </div>
            <div className="col-md-4">
              <div className="">
                <div className="founder-main-about-2">
                  <img src="/Icons/slider.jpg" alt="Image" />
                  <div className="founder-profile-about py-4">
                    <div className="founder-profile-text-about">
                      <h4>Shubham Gupta</h4>
                      <p>Co-Founder and CEO</p>
                    </div>
                  </div>
                  <p>
                    Shubham Gupta, a former Investment Banker started his
                    journey into the financial world with a Gold Medal in B.Com.
                    A semi qualified Chartered Accountant, worked as an Auditor
                    for 5 years in Deloitte and finally co-founded Vinest to
                    solve the problem of fundraising for businesses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="">
                <div className="founder-main-about-2">
                  <img src="/Icons/slider2.jpg" alt="Image" />
                  <div className="founder-profile-about py-4">
                    <div className="founder-profile-text-about">
                      <h4>Pushkar Sabharwal</h4>
                      <p>Co- Founder and CBO</p>
                    </div>
                  </div>
                  <p>
                    Pushkar Sabharwal, an entrepreneur at heart has a vast
                    experience of over 7 years in companies like American
                    Express, Zomato, Shiprocket and Recur Club. He has had the
                    chance to work on new ideas and verticals many of his work
                    profiles. He has now embarked his journey on Vinest by
                    choosing to build a platform from scratch that will help
                    connect the right set of resources through AI and
                    matchmaking.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
