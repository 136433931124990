import React, { useState, useEffect } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { fetchSingleStartupForEdit } from "../Apis";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { EditAndupdateStartupDocument, singleFileUpload } from "../Apis";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { setLoader } from "../Redux";

import "./../css/editdetails.css";

function EditDocuments({ getIsLodaerRedux, setIsLoaderRedux }) {
  const navigation = useNavigate();
  const pathID = window.location.pathname.split("/")[2];

  const [updatePage , setUpdatePage] = useState(false)
  const [msi, setMsi] = useState([]);
  const [provisions, setProvisions] = useState([]);
  const [auditedFinancial, setAuditedFinancial] = useState([]);
  const [salesRegister, setSalesRegister] = useState([]);
  const [bankStatements, setBankStatements] = useState([]);
  const [gst, setGst] = useState([]);
  const [debt_profile, setdebt_profile] = useState([]);
  const [pan_number, setpan_number] = useState([]);
  const [coi, setcoi] = useState([]);
  const [din, setdin] = useState([]);
  const [board_resolutions, setboard_resolutions] = useState([]);

  const [debt_profileEdit, setdebt_profileEdit] = useState([]);
  const [msiEdit, setMsiEdit] = useState([]);
  const [provisionsEdit, setProvisionsEdit] = useState([]);
  const [auditedFinancialEdit, setAuditedFinancialEdit] = useState([]);
  const [salesRegisterEdit, setSalesRegisterEdit] = useState([]);
  const [bankStatementsEdit, setBankStatementsEdit] = useState([]);
  const [gstEdit, setGstEdit] = useState([]);
  const [pan_numberEdit, setpan_numberEdit] = useState([]);
  const [coiEdit, setcoiEdit] = useState([]);
  const [dinEdit, setdinEdit] = useState([]);
  const [board_resolutionsEdit, setboard_resolutionsEdit] = useState([]);

  const [debt_profileLoader, setdebt_profileLoader] = useState(false);
  const [msiLoader, setMsiLoader] = useState(false);
  const [provisionsLoader, setProvisionsLoader] = useState(false);
  const [auditedFinancialLoader, setAuditedFinancialLoader] = useState(false);
  const [salesRegisterLoader, setSalesRegisterLoader] = useState(false);
  const [bankStatementsLoader, setBankStatementsLoader] = useState(false);
  const [gstLoader, setGstLoader] = useState(false);
  const [pan_numberLoader, setpan_numberLoader] = useState(false);
  const [coiLoader, setcoiLoader] = useState(false);
  const [dinLoader, setdinLoader] = useState(false);
  const [board_resolutionsLoader, setboard_resolutionsLoader] = useState(false);

  useEffect(() => {
    fetchSingleStartupForEdit(pathID,setIsLoaderRedux, (data) => {
      let documentData = data.documents;

      setdebt_profile(documentData?.debt_profile);
      setcoi(documentData?.coi);
      setMsi(documentData?.mis);
      setdin(documentData?.din);
      setboard_resolutions(documentData?.board_resolutions);
      setpan_number(documentData?.pan_number);
      setGst(documentData?.gst);
      setBankStatements(documentData?.bank_statements);
      setSalesRegister(documentData?.sales_register);
      setAuditedFinancial(documentData?.audited_financial);
      setProvisions(documentData?.provisions);
    });
    
  }, [updatePage]);

  const onHandleUpdate = () => {
    let formData = {
      id: pathID,
      mis: msiEdit?.length===0?  msi:msiEdit,
      provisions: provisionsEdit?.length===0 ? provisions :provisionsEdit,
      audited_financial:auditedFinancialEdit?.length===0? auditedFinancial: auditedFinancialEdit,
      sales_register: salesRegisterEdit?.length===0 ? salesRegister:salesRegisterEdit,
      bank_statements: bankStatementsEdit?.length===0 ? bankStatements :bankStatementsEdit,
      gst: gstEdit?.length===0 ? gst :gstEdit,
      debt_profile: debt_profileEdit?.length ===0 ? debt_profile:debt_profileEdit,
      pan_number: pan_numberEdit?.length===0 ?pan_number :pan_numberEdit,
      coi: coiEdit?.length===0 ?coi :coiEdit,
      din: dinEdit?.length===0 ? din :dinEdit,
      board_resolutions: board_resolutionsEdit?.length===0 ?board_resolutions :board_resolutionsEdit,
    };
    EditAndupdateStartupDocument(formData, navigation);
    setUpdatePage(!updatePage)
  };
  
  return (
    <div className="container">
      <ToastContainer position="top-center" />
      <h4 className="text-start mt-4 fw-bold">Documents Details</h4>
      <div className="row">

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>Debt Profile</h5>
          </div>
          <div className="col-md-6">
            {debt_profile?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="Debt">
              
                  {debt_profileLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : debt_profileEdit.length === 0 ? (
                      <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}

                  <input
                    name="files[]"
                    multiple
                    id="Debt"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".csv,.xlsx"
                    onChange={(event) => {
                      if (event.target.files.length > 4) {
                        toast.error("Files Should Not be Above 4");
                      } else {
                        singleFileUpload(
                          pathID,
                          event.target.files,
                          "debt_profile",
                          event.target.files.length,
                          setdebt_profileLoader,
                          (data) => {
                            setdebt_profileEdit(data);
                          }
                        );
                      }
                    }}
                  />
                </label>
                <h6>Only:.csv,.xlsx</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                 { debt_profile?.map((item, i) => {
                    return (
                      <div className="mx-4" key={i}>
                        <a href={debt_profile[i]} target="blank">
                          View File{i + 1}{" "}
                        </a>
                      </div>
                    );
                  })}
                
              </div>
            )}
          </div>

          <div className="col-md-4 d-flex justify-content-end">
            {debt_profile?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setdebt_profile([]);
                    setdebt_profileEdit([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>COI</h5>
          </div>
          <div className="col-md-6">
            {coi?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="coi">
                {coiLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : coiEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="coi"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".pdf,.jpeg,.png,.jpg"
                    onChange={(event) => {
                       if (event.target.files.length > 4) {
                        toast.error("Files Should Not be Above 4");
                      } else {
                        singleFileUpload(
                          pathID,
                          event.target.files,
                          "coi",
                          event.target.files.length,
                          setcoiLoader,
                          (data) => {
                            setcoiEdit(data);
                          }
                        );
                      }
                    }}
                  />
                </label>
                <h6>Only:.pdf,.jpeg,.png,.jpg</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {coi?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                      <a href={coi[i]} target="blank">
                        View File{i + 1}{" "}
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {coi?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setcoi([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>MIS</h5>
          </div>
          <div className="col-md-6">
            {msi?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="mis">
                {msiLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : msiEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="mis"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".csv,.xlsx"
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "mis",
                              event.target.files.length,
                              setMsiLoader,
                              (data) => {
                                setMsiEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.csv,.xlsx</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {msi?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                      <a href={msi[i]} target="blank">
                        View File{i + 1}{" "}
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {msi?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setMsi([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>DIN</h5>
          </div>
          <div className="col-md-6">
            {din?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="din">
                {dinLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : dinEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="din"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".pdf, jpeg, png, jpg."
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "din",
                              event.target.files.length,
                              setdinLoader,
                              (data) => {
                                setdinEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.pdf, jpeg, png, jpg.</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {din?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                    <a href={din[i]} target="blank">
                      View File{i + 1}{" "}
                    </a>
                  </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {din?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setdin([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>Board Resolutions</h5>
          </div>
          <div className="col-md-6">
            {board_resolutions?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="board_resolutions">
                {board_resolutionsLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : board_resolutionsEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="board_resolutions"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".pdf,.jpeg,.png,.jpg"
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "board_resolutions",
                              event.target.files.length,
                              setboard_resolutionsLoader,
                              (data) => {
                                setboard_resolutionsEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.pdf,.jpeg,.png,.jpg</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {board_resolutions?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                    <a href={board_resolutions[i]} target="blank">
                      View File{i + 1}{" "}
                    </a>
                  </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="col-md-4 d-flex justify-content-end">
            {board_resolutions?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setboard_resolutions([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>Pan Number</h5>
          </div>
          <div className="col-md-6">
            {pan_number?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="pan_number">
                 {pan_numberLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : pan_numberEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="pan_number"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".pdf,.jpeg,.png,.jpg"
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "pan_number",
                              event.target.files.length,
                              setpan_numberLoader,
                              (data) => {
                                setpan_numberEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.pdf,.jpeg,.png,.jpg</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {pan_number?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                    <a href={pan_number[i]} target="blank">
                      View File{i + 1}{" "}
                    </a>
                  </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {pan_number?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setpan_number([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>GST</h5>
          </div>
          <div className="col-md-6">
            {gst?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="gst">
                 {gstLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : gstEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="gst"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".pdf,.zip"
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "gst",
                              event.target.files.length,
                              setGstLoader,
                              (data) => {
                                setGstEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.pdf,.zip</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {gst?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                    <a href={gst[i]} target="blank">
                      View File{i + 1}{" "}
                    </a>
                  </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {gst?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setGst([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>Bank Statements</h5>
          </div>
          <div className="col-md-6">
            {bankStatements?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="bankStatements">
                 {bankStatementsLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : bankStatementsEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="bankStatements"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".pdf,.zip"
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "bank",
                              event.target.files.length,
                              setBankStatementsLoader,
                              (data) => {
                                setBankStatementsEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.pdf,.zip</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {bankStatements?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                    <a href={bankStatements[i]} target="blank">
                      View File{i + 1}{" "}
                    </a>
                  </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {bankStatements?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setBankStatements([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>Sales Register</h5>
          </div>
          <div className="col-md-6">
            {salesRegister?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="salesRegister">
                 {salesRegisterLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : salesRegisterEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="salesRegister"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".xlsx,.csv,.xls,.jpeg,.png,.jpg,.gif,.svg,.pdf"
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "sales_register",
                              event.target.files.length,
                              setSalesRegisterLoader,
                              (data) => {
                                setSalesRegisterEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.xlsx,.csv,.xls,.jpeg,.png,.jpg,.gif,.svg,.pdf</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {salesRegister?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                    <a href={salesRegister[i]} target="blank">
                      View File{i + 1}{" "}
                    </a>
                  </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {salesRegister?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setSalesRegister([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>Audited Financial</h5>
          </div>
          <div className="col-md-6">
            {auditedFinancial?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="auditedFinancial">
                 {auditedFinancialLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : auditedFinancialEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="auditedFinancial"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".pdf"
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "audited_financial",
                              event.target.files.length,
                              setAuditedFinancialLoader,
                              (data) => {
                                setAuditedFinancialEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.pdf</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {auditedFinancial?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                    <a href={auditedFinancial[i]} target="blank">
                      View File{i + 1}{" "}
                    </a>
                  </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {auditedFinancial?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setAuditedFinancial([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>

        <div className="edit-basic-detail-main row  row  py-3">
          <div className="col-md-2">
            <h5>Provisions</h5>
          </div>
          <div className="col-md-6">
            {provisions?.length === 0 ? (
              <div className="upload_document_field">
                <label htmlFor="provisions">
                 {provisionsLoader ? (
                    <CircularProgress  size={18} color="success" />
                  ) : provisionsEdit.length === 0 ? (
                    <FileUploadIcon />
                  ) : (
                    <BsCheck2Circle style={{ color: "green" }} />
                  )}
                  <input
                    name="files[]"
                    multiple
                    id="provisions"
                    type="file"
                    placeholder="something"
                    className="form-control "
                    accept=".pdf"
                    onChange={(event) => {
                        if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            singleFileUpload(
                              pathID,
                              event.target.files,
                              "provisions",
                              event.target.files.length,
                              setProvisionsLoader,
                              (data) => {
                                setProvisionsEdit(data);
                              }
                            );
                          }
                    }}
                  />
                </label>
                <h6>Only:.pdf</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                {provisions?.map((item, i) => {
                  return (
                    <div className="mx-4" key={i}>
                    <a href={provisions[i]} target="blank">
                      View File{i + 1}{" "}
                    </a>
                  </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            {provisions?.length === 0 ? (
              <button className="pending_button">Pending</button>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setProvisions([]);
                  }}
                  className="edit-upload-button"
                >
                  Edit
                </button>
                <button>Uploaded</button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="edit-info-btn mb-5">
        <button onClick={onHandleUpdate}>UPDATE</button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getIsLodaerRedux: state.isLoader.isLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoaderRedux: (parameter) => {
      dispatch(setLoader(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDocuments);
