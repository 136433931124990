import {createStore, combineReducers , applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import userDataReducer from './reducers/userDataReducer';
import sliderImageReducer from './reducers/sliderImageReducer';
import updateScreenReducer from './reducers/updateScreenReducer';
import categoryIdReducer from './reducers/categoryIdReducer';
import singleStartupIdReducer from './reducers/singleStartupIdReducer';
import loaderReducer from './reducers/loaderReducer';

export const rootReducer =  combineReducers({
user:userDataReducer,
slider:sliderImageReducer,
update:updateScreenReducer,
categoryId :categoryIdReducer,
singleStartupId:singleStartupIdReducer,
isLoader:loaderReducer
   
});
  
// Passing burgerReducer to createStore
const store=createStore(rootReducer, applyMiddleware(thunk));
  
export default store;