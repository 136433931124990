import React from "react";
import Carousel from "react-elastic-carousel";
import "../css/funding.css";

function Funding() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  

  return (
    <div>
      <div className="container my-5 my-sm-2 py-2 py-sm-5">
        <div className="column">
          <div className="funding-heading">
            <h1>
               <span>Hundreds of Founders Trust Us With Their Financing</span>
            </h1>
            <p></p>
          </div>
          <div className="carousel my-4">
            <Carousel breakPoints={breakPoints} pagination={false} autoPlaySpeed={5000} enableAutoPlay={true}>
              <div className="funding-card">
                <h2>RBF</h2>
                
              </div>
              <div className="funding-card">
                <h2>Debt Capital</h2>
                
              </div>
              <div className="funding-card">
                <h2>Invoice Discounting</h2>
                
              </div>
              <div className="funding-card">
                <h2>Import/Export Financing</h2>
                
              </div>
              <div className="funding-card">
                <h2>Vendor Financing Solutions </h2>
                
              </div>
              <div className="funding-card">
                <h2>Supply Chain Finance Solutions </h2>
                
              </div>
              <div className="funding-card">
                <h2>Bill Discounting Solutions </h2>
                
              </div>
              <div className="funding-card">
                <h2>Purchase Order Financing </h2>
                
              </div>
              <div className="funding-card">
                <h2>Vendor Factoring Solutions </h2>
                
              </div>
              <div className="funding-card">
                <h2>Dynamic Discounting Solutions </h2>
                
              </div>

              <div className="funding-card">
                <h2>Anchor Financing Solutions </h2>
                
              </div>
              <div className="funding-card">
                <h2>Invoice Discounting </h2>
                
              </div>
              <div className="funding-card">
                <h2>Receivable Financing </h2>
                
              </div>
              <div className="funding-card">
                <h2>Early Payment Discount </h2>
                
              </div>
              <div className="funding-card">
                <h2>Invoice Financing </h2>
                
              </div>
              <div className="funding-card">
                <h2>Invoice Factoring</h2>
                
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Funding;
