import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Checkbox from "@mui/material/Checkbox";
import "../css/modalcss/filtermodal.css";

function valuetext(value) {
  return `${value}°C`;
}
const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];

const theme = createTheme({
  palette: {
    neutral: {
      main: "#FE5902",
      contrastText: "#fff",
    },
  },
});

export function RangeSlider() {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ marginTop: 0 }}>
      <h4>Equity : </h4>
      <ThemeProvider theme={theme}>
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          marks={marks}
          color="neutral"
        />
      </ThemeProvider>
      <div>
        <span>Min : {value[0]}</span> <span>Max : {value[1]}</span>
      </div>
    </Box>
  );
}

export const MultiSelectFilter = () => {
  const [field, setField] = useState([]);
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Category</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Category" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {names.map((name, i) => (
            <MenuItem key={i} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const DateFilter = () => {
  const [value, setValue] = React.useState();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: "100%" }}
        label="Select Date"
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />
    </LocalizationProvider>
  );
};

export const InvestType = () => {
  return (
    <div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Equity"
          name="radio-buttons-group"
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              value="Equity"
              control={
                <Radio
                  sx={{
                    color: "#FE5902",
                    "&.Mui-checked": {
                      color: "#FE5902",
                    },
                  }}
                />
              }
              label="Equity"
            />
            <FormControlLabel
              value="Debt"
              control={
                <Radio
                  sx={{
                    color: "#FE5902",
                    "&.Mui-checked": {
                      color: "#FE5902",
                    },
                  }}
                />
              }
              label="Debt"
            />
            <FormControlLabel
              value="Both"
              control={
                <Radio
                  sx={{
                    color: "#FE5902",
                    "&.Mui-checked": {
                      color: "#FE5902",
                    },
                  }}
                />
              }
              label="Both"
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

function FilterModal({ openFilterModal, setOpenFilterModal }) {
  const handleClose = () => setOpenFilterModal(false);
  return (
    <div>
      <Modal
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        show={openFilterModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply Filters</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="filters-apply p-1">
            <div className="filter-apply-first p-3 shadow rounded">
              <RangeSlider />
            </div>

            <div className="filters-apply-date p-3 shadow mt-4 rounded">
              <h4>Date : </h4>
              <DateFilter />
            </div>

            <div className="multislect-filter p-3 shadow mt-4 rounded">
              <h4>Category : </h4>
              <MultiSelectFilter />
            </div>

            <div className="multislect-filter p-3 shadow mt-4 rounded">
              <h4>Type of Invest : </h4>
              <InvestType />
            </div>
            <div className=" filter-apply-button-modal p-3 mt-4">
              <button onClick={handleClose}>APPLY</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FilterModal;
