import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../Redux/actions";
import { connect } from "react-redux";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import TopBar from "../components/TopBar";
import LoginDetailsPage from "../components/LoginDetailsPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { forgotPasswordApi } from "../Apis";
import "../css/startuplogin.css";

function ForgotPassword({userDataRedux}) {
  const navigation = useNavigate();

  const [email,setEmail]= useState('')

const handleForgotClick=()=>{
    forgotPasswordApi(email, (data)=>{
        if(data.status==='success'){
            toast(data.message)
            navigation('/')
          } else if(data.message==='failed'){
            toast.error(data.message)
          }
    })
}
  return (
    <>
    <ToastContainer />
      <TopBar />
       <div className="upper-login">
       <NavBar />
       </div>
      <div className="startup">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 left-start-up-login">
                <OwlCarousel
                  className="owl-theme"
                  autoplayTimeout={4000}
                  loop
                  nav={true}
                  autoplay={true}
                  items={1}
                  dots={true}
                >
                  <div className="slideshow">
                    <h2>Join our</h2>
                    <h1>Partner Program</h1>
                    <p>
                      Earn <span>upto 10 lac/month</span> while helping your
                      clients grow faster with non-dilutive capital from Recur
                      Club.
                    </p>
                  </div>
                  <div className="slideshow">
                    <h2>Join our</h2>
                    <h1>Partner Program</h1>
                    <p>
                      Earn <span>upto 10 lac/month</span> while helping your
                      clients grow faster with non-dilutive capital from Recur
                      Club.
                    </p>
                  </div>
                </OwlCarousel>
              </div>
              <div className="col-md-2"></div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="right-start-up-login">
                <div className="form">
                  <h1>Welcome to V!nest</h1>
                  <div className="login-input-single mt-3">
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Enter Registered Email To Send Link"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                    />
                  </div>

                  <div className="forgot-buttons">
                    <button
                      className="forgot-btn"
                      onClick={handleForgotClick}
                    >
                      Send
                    </button>
                  </div>

                  <p className="dont-account-login">
                    Don’t have an account?{" "}
                    <span onClick={() => navigation("/dashboard")}>
                      Sign-up
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Partner /> */}
      <LoginDetailsPage />
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    userDataRedux: (parameter) => {
      dispatch(setUserData(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

