import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/welcome.css";
import { Button } from "bootstrap";

function PartnerWelcome() {
  const navigation = useNavigate();

  useEffect(() => {}, []);

  return (
    <div className="welcome d-flex justify-content-center flex-column">
      <h3 className="text-center">
        Become a Channel Partner of India's Leading{" "}
      </h3>
      <h3 className="heading-partner-welocome text-center">
        <span>Financing Platform</span>
      </h3>
      <p className="text-center">You Can Refer Clients as well as Investors</p>
      <img
        className="text-center m-auto d-flex justify-content-center"
        src="/img/hand-partner.png"
        alt=""
      />
      <div className="d-flex justify-content-center my-5">
        <a href="https://vinest.aicatech.in/investor/partner-signup" target="_black" className="register-btn">Become Partner</a>
      </div>
      <div className="d-flex justify-content-between m-auto">
        <div className="welcome-section   ">
          <img src="/img/welcome.png" alt="img" />
          <h5>Earn Attractive Commission</h5>
        </div>

        <div className="welcome-section ">
          <img src="/img/welcome1.png" alt="img" />
          <h5>Completely Online Process</h5>
        </div>

        <div className="welcome-section ">
          <img src="/img/welcome2.png" alt="img" />
          <h5>Online Dashboard to Check Status</h5>
        </div>
      </div>
    </div>
  );
}

export default PartnerWelcome;
