import { LOADER_STATE } from "../type"

const initialState = {
    isLoader: false
}

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADER_STATE: return {
            ...state,
            isLoader: action.payload
        }
        default: return state
    }
}

export default loaderReducer;