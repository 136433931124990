import React, { useEffect} from "react";
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "./../baseUrl";
import { connect } from "react-redux";



 function GoogleLogin(role,navigation, callback) {
  const provider = new GoogleAuthProvider();

      signInWithPopup(authentication, provider)
      .then((res) => {

        let data = JSON.stringify({
          email: res.user.email,
          name: res.user.displayName,
          role: role,
          outh_uid: res.user.uid,
        });
      
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}google-login`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        
       axios
          .request(config)
          .then((response) => {
            let data = response.data.data;
            if (response.data.status === "failed") {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast(response.data.message);
              localStorage.setItem("VinestToken", JSON.stringify(data?.token));
              if(data?.user?.role==="4"||data?.user?.role===4){
                
                if(data?.user?.city===null || data?.user?.profession===null || data?.user?.phone===null||data?.user?.city===undefined || data?.user?.profession===undefined || data?.user?.phone===undefined){
                  localStorage.setItem("vinestInfoModal", true);
                 
                }else{
                  localStorage.setItem("vinestInfoModal", false);
                  
                }
                navigation('/dashboard')
                window.location.reload()
              } 

              if(data?.user?.role==="1" || data?.user?.role===1){
                localStorage.setItem("vinestStartupId", JSON.stringify(data?.user?.startupId));
                navigation('/add-startup')
                window.location.reload()
              }
             
              return callback(response.data);
            }
            
          })
          .catch((error) => {
            toast.error("Something Went Wrong", {
              position: toast.POSITION.TOP_RIGHT,
            });
            
          });
      
      })
      .catch((err) => {
        console.log(err , 'google err')
      });
}

export default GoogleLogin