import React from "react";
import "../css/middlesection.css";

function MiddleSection() {
  return (
    <>
      <div className="middle-section">
        <div className="container">
          <div className="column">
            <div className="middle-section-first">
              <h1>India's Platform To Meet Your Funding Demands</h1>
              <p>We are your reliable Partner for Entrepreneurial success </p>
            </div>
          </div>
          <div className="container my-5">
            <div className="row">
              <div className="col-md-3">
                <div className="section_card">
                  <img src="/Icons/menwith.png" alt="Image" />
                  <h1>Founder-Friendly Capital</h1>
                  <p>
                  Fast, flexible, and non-dilutive financing up to US$ 5 Million.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="section_card">
                  <img src="/Icons/mens.png" alt="image" />
                  <h1>Dynamic Reporting</h1>
                  <p>
                    Powerful business intelligence and optimization tools to
                    give you the inside edge.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="section_card">
                  <img src="/Icons/scope.png" alt="image" />
                  <h1>Diverse Partner Network</h1>
                  <p>
                  A diverse partner network refers to a collaborative network or ecosystem that includes individuals
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="section_card">
                  <img src="/Icons/circle.png" alt="image" />
                  <h1>Reliable Founder Community</h1>
                  <p>
                    Access to our wide network of mentors to help you overcome
                    growth challenges.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MiddleSection;
