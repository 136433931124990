import { SLIDER_IMAGES } from "../type"

const initialState = {
    data: null
}

const sliderImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SLIDER_IMAGES: return {
            ...state,
            data: action.payload
        }
        default: return state
    }
}

export default sliderImageReducer;