import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterStartup from '../components/FooterStartup'
import NavBar from "../components/NavBar";
import TopBar from "../components/TopBar";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import LoginDetailsPage from "../components/LoginDetailsPage";
import GoogleLogin from "./GoogleLogin";
import { setUserData } from "../Redux/actions";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getLoginApiForStartup } from "../Apis";
import PartnerBelow from "./PartnerBelow";
import Entrepreneur from "../components/Entrepreneur";
import BusinessModel from "../components/BusinessModel";
import Founder from "../components/Founder";
import Partner from "../components/Partner";
import Funding from "../components/Funding";

import MiddleSectionFooter from "../components/MiddleSectionFooter";
import "../css/startuplogin.css";

let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
var token = localStorage.getItem("VinestToken");
function StartupLogin({ userDataRedux, getuserDataRedux }) {
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordShow, setisPasswordShow] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const googleLogin = () => {
    GoogleLogin(1, navigation, (data) => {
      userDataRedux(data);
    });
  };

  const handleLoginButton = () => {
    if (email === "" || password === "") {
      toast.error("Fields Should Not Be Empty", {
        theme: "colored",
      });
      setEmailError(true);
      setPasswordError(true);
    } else if (emailreg.test(email) === false) {
      toast.error("Email Format is not correct it Contain @ & .com", {
        theme: "colored",
      });
      setEmailError(true);
    } else {
      getLoginApiForStartup(email, password, navigation, "1");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ToastContainer />
      <TopBar />
      <div className="upper-login">
        <NavBar />
      </div>
      <div className="startup-page">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-md-8">
              <div className="row login-slider-show-main">
                <h2 className="text-white text-start py-2">
                Welcome to modern funding for 21st-century businesses.
                </h2>
                <h1 className="text-white text-start py-0">
                Looking for funding up to INR 50 crores? 
                </h1>
                <h1 className="text-white text-start ">We'll provide a term sheet within 72 hours.</h1>
                <div className="col-md-8 left-start-up-login">
                  <OwlCarousel
                    className="owl-theme"
                    autoplayTimeout={4000}
                    loop
                    nav={true}
                    autoplay={true}
                    items={1}
                    dots={false}
                  >
                    <div className="slideshow">
                      <div className="login-slider-show">
                      <img src="/img/juneja.png" alt="img" />
                          <h5 className="text-center py-2">We are thrilled to announce the successful fundraising round with Vinest. This will enable us to enhance our product range and help our marketing efforts to provide customers with an unmatched shopping experience.</h5>
                            <h2 className="text-center py-2">Garima Juneja</h2>
                            <h3 className="text-center ">Co-Founder and CEO at Praush Beauty (Formerly PLUME)</h3>
                      </div>
                    </div>

                    <div className="slideshow">
                      <div className="login-slider-show">
                      <img src="/img/Akanksha.png" alt="img" />
                          <h5 className="text-center py-2">Raising funds with the invaluable assistance of Vinest has been an exceptionally smooth and efficient journey. In a realm where financial transactions can often be complex and demanding, Vinest's support has truly set a benchmark.</h5>
                            <h2 className="text-center py-2">Akanksha Kanwal Shukla</h2>
                            <h3 className="text-center ">Co-Founder at Beatitude</h3>
                      </div>
                    </div>
                 
                
                   
                  </OwlCarousel>
                </div>
                <h6 className="text-white text-start py-2">
                India's leading 200+ businesses have joined the Vinest
                </h6>
              </div>
            </div>

            <div className="col-md-4">
              <div className="right-start-up-login">
                <div className="form">
                  <h1>Welcome to V!nest</h1>
                  <div className="login-input-single mt-3">
                    <label
                      style={{ color: passwordError ? "red" : "#000" }}
                      htmlFor="fname"
                    >
                      Email
                    </label>
                    <input
                      style={{
                        color: emailError ? "red" : "#000",
                        borderColor: emailError ? "red" : "rgb(182, 174, 174)",
                      }}
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="login-input-single mt-4">
                    <label htmlFor="password">Password</label>
                    <input
                      style={{
                        color: passwordError ? "red" : "#000",
                        borderColor: passwordError
                          ? "red"
                          : "rgb(182, 174, 174)",
                      }}
                      type={isPasswordShow ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="********"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {isPasswordShow ? (
                      <AiFillEyeInvisible
                        onClick={() => setisPasswordShow(!isPasswordShow)}
                        className="eye-icons-login"
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setisPasswordShow(!isPasswordShow)}
                        className="eye-icons-login"
                      />
                    )}
                  </div>
                  <p
                    className="forgot-password"
                    onClick={() => navigation("/forgot-password")}
                  >
                    Forgot Password
                  </p>

                  <div className="login-buttons">
                    <div className="google-login-btn" onClick={googleLogin}>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-9 google-button">
                            <p>Sign in with</p>
                          </div>
                          <div className="col-md-3 google-button">
                            <img src="/Icons/google-icon.png" alt="Image" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button className="login-btn" onClick={handleLoginButton}>
                      Login
                    </button>
                  </div>

                  <p className="dont-account-login">
                    Don’t have an account?{" "}
                    <span onClick={() => navigation("/startup-register")}>
                      Sign-up
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      <Partner/>
      <Funding />
      <MiddleSectionFooter />

      <Entrepreneur />
      <BusinessModel />
      <Founder />

      <FooterStartup />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getuserDataRedux: state.user.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userDataRedux: (parameter) => {
      dispatch(setUserData(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartupLogin);
