import React,{useEffect} from 'react';
import Home from './components/Home';
import StartupLogin from './auth/StartupLogin';
import PartnerLogin from './auth/PartnerLogin';
import RegisterPartner from './auth/RegisterPartner';
import RegisterStartup from './auth/RegisterStartup';
import PartnerWelcome from './auth/PartnerWelcome';
import Dashboard from './dashboards/index';
import AddStartupPage from './pages/AddStartupPage';
import UploadDocumentStartupPage from './pages/UploadDocumentStartupPage';
import UploadDocumentStartupPage2 from './pages/UploadDocumentStartupPage2';
import Profile from './components/Profile';
import Contact from './pages/Contact';
import About from './pages/About';
import GoogleSearch from './GoogleSearch';
import Privacy from './Privacy/Privacy';
import Term from './Privacy/Term';
import Blog from './pages/Blog';
import Faq from './pages/Faq'
import InvestorLogin from './auth/InvestorLogin';
import ForgotPassword from './auth/ForgotPassword';
import RegisterStartup2 from './auth/RegisterStartup2';
import RegisterStartup3 from './auth/RegisterStartup3';
import { connect } from 'react-redux';
import EditDetailsPage from './pages/EditDetailsPage';
import { fetchSingleStartup } from './Apis';
import { useNavigate } from 'react-router-dom';
import { setUserData, setSliderImages, setUpdateScreen, setSingleStartupId } from './Redux/actions';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { fetchUserDetails, fetchSliders } from './Apis';

var token = localStorage.getItem("VinestToken");
function App({userDataRedux, setSliderImageRedux, setUpdateScreenRedux, updateScreen, getuserDataRedux,setSingleStartupIdRedux,getSingleStartupIdRedux,navigation,getIsLodaerRedux}) {

  useEffect(()=>{
    fetchUserDetails((data)=>{
      userDataRedux(data)
      setSingleStartupIdRedux(data.data?.user?.startupId)
    })

    fetchSliders((data)=>{
      setUpdateScreenRedux(!updateScreen)
      setSliderImageRedux(data?.data?.slider)
    })

  },[])

  useEffect(()=>{
    window.scrollTo({top: 0,behavior: 'smooth'});
  },[])
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: (<Home/> ),
    },
    {
      path: "startup-login",
      element: <StartupLogin/>,
    },
    {
      path: "partner-login",
      element: <PartnerLogin/>,
    },
    {
      path: "partner-register",
      element: <RegisterPartner/>,
    },
    {
      path:"startup-register",
      element:<RegisterStartup/>
    },
    {
      path:"partner-welcome",
      element:<PartnerWelcome/>
    },
    {
      path:"dashboard",
      element:<Dashboard/>
    },
    {
      path:"add-startup",
      element:<AddStartupPage/>
    },
    {
      path:"startup-upload-documents",
      element:<UploadDocumentStartupPage/>
    },
    {
      path:"startup-upload-documents-2",
      element:<UploadDocumentStartupPage2/>
    },
    {
      path:"search",
      element:<GoogleSearch/>
    },
    {
      path:"about",
      element:<About/>
    },
    {
      path:"contact",
      element:<Contact/>
    },
    {
      path:"profile",
      element:<Profile/>
    },
    {
      path:'privacy',
      element:<Privacy/>
    },
    {
      path:'terms-conditions',
      element:<Term/>
    },
    {
      path:'blog',
      element:<Blog/>
    },
    {
      path:'faq',
      element:<Faq/>
    },
    {
      path:'investor-login',
      element:<InvestorLogin/>
    },
    {
      path:'forgot-password',
      element:<ForgotPassword/>
    },
    {
      path:'startup-register/:slug/:slug/:slug/:slug/:slug/:slug',
      element:<RegisterStartup2/>
    },
    {
      path:'startup-register/:slug',
      element:<RegisterStartup3/>
    },
    {
      path:'/edit-details/:slug',
      element:<EditDetailsPage/>
    }
  ]);
  
  return (
    <div className="App">
     <RouterProvider exact router={router} />
    </div>
  );
}



const mapStateToProps = state => {
  return {
    getuserDataRedux:state.user.data,
    updateScreen:state.update.data,
    getSingleStartupIdRedux: state.singleStartupId.id,
    getIsLodaerRedux: state.isLoader.isLoader,
  };
};
const mapDispatchToProps = dispatch => {
  return {
 userDataRedux: parameter => {
      dispatch(setUserData(parameter));
    },
    setSliderImageRedux:parameter=>{ dispatch(setSliderImages(parameter))},
    setUpdateScreenRedux:parameter=>{ dispatch(setUpdateScreen(parameter))},
    setSingleStartupIdRedux: (parameter) => {
      dispatch(setSingleStartupId(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

