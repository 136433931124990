import { SINGLE_STARTUP_ID } from "../type"

const initialState = {
    id: null
}

const singleStartupIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case SINGLE_STARTUP_ID: return {
            ...state,
            id: action.payload
        }
        default: return state
    }
}

export default singleStartupIdReducer;