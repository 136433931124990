import React, { useState, useEffect } from "react";
import DasboardNav from "../dashboards/DasboardNav";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import EditBasicDetails from "../EditPages/EditBasicDetails";
import EditFinancialDetails from "../EditPages/EditFinancialDetails";
import EditDocuments from "../EditPages/EditDocuments";
import "./../css/editdetails.css";

function EditDetailsPage({getIsLodaerRedux}) {



  return (
    <div>
         {getIsLodaerRedux ? (
        <div className="loader-spinner-main">
          <CircularProgress className="loader-spinner" color="success" />
        </div>
      ) : null}

      <DasboardNav />
      <EditBasicDetails />
      <EditFinancialDetails/>
      <EditDocuments/>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    getIsLodaerRedux: state.isLoader.isLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDetailsPage);


