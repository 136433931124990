import React, { useEffect, useState } from "react";
import DashboardChart from "./DashboardChart";
import DashboardCategorySlider from "./DashboardCategorySlider";
import DashboardBanner from "./DashboardBanner";
import DashboardFIlter from "./DashboardFIlter";
import DashboardList from "./DashboardList";
import TableDashboard from "./TableDashboard";
import { Pagination } from "@mui/material";
import { setLoader } from "../Redux";
import { fetchStartup } from "../Apis";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "../cssdashboard/index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SearchingApi } from "../Apis";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#343C5C",
      contrastText: "#fff",
    },
  },
});

var token = localStorage.getItem("VinestToken");

function DashboardPage({
  getuserDataRedux,
  forReloadDashboardTable,
  setIsLoaderRedux,
  getUpdateScreenRedux,
}) {
  const navigation = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [selectType, setSelectType] = useState(1);
  const [lengthData, setLengthData] = useState();

  useEffect(() => {
    fetchStartup(setIsLoaderRedux, selectType, (data) => {
      setTableData(data?.data?.startups);
      setLengthData(data);
    });
  }, [selectType]);

  const handleChangeSearch = (e) => {
    let formdata = {
      type: selectType,
      page: 1,
      limit: 10,
      keywords: e.target.value,
    };
    SearchingApi(formdata, (data)=>{
      setTableData(data);
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <DashboardFIlter
            setSelectType={setSelectType}
            lengthData={lengthData}
            handleChangeSearch={handleChangeSearch}
          />
          <div className="col-md-8">
            <div className="dashboard-slider-category-main">
              {tableData?.length === 0 ? null : (
                <DashboardCategorySlider tableData={tableData} />
              )}
            </div>
            <div className="table-dashboard mt-2">
              {tableData?.length === 0 ? (
                <h3>No Data Found </h3>
              ) : (
                <TableDashboard tableData={tableData} />
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="chart-table-dashboard">
              <DashboardBanner />
            </div>
            <div className="listing-dashboard">
              <DashboardList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getuserDataRedux: state.user.data,
    getUpdateScreenRedux: state.update.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoaderRedux: (parameter) => {
      dispatch(setLoader(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
