import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import NavBar from "../components/NavBar";
import { setUserData } from "../Redux/actions";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { connect } from "react-redux";
import FooterStartup from "../components/FooterStartup";
import LoginDetailsPage from "../components/LoginDetailsPage";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import GoogleLogin from "./GoogleLogin";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer, toast } from "react-toastify";
import { registrationApi } from "../Apis";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Partner from "../components/Partner";
import Funding from "../components/Funding";
import MiddleSectionFooter from "../components/MiddleSectionFooter";

import Entrepreneur from "../components/Entrepreneur";
import BusinessModel from "../components/BusinessModel";
import Founder from "../components/Founder";

import "../css/register.css";

let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
var token = localStorage.getItem("VinestToken");
function RegisterStartup({ userDataRedux, getuserDataRedux }) {
  const navigation = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [referralCode, setreferralCode] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [isPasswordShow, setisPasswordShow] = useState(false);
  const [isCPasswordShow, setisCPasswordShow] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const googleLogin = () => {
    GoogleLogin(1, navigation, (data) => {
      userDataRedux(data);
    });
  };

  const handleClickRegistration = () => {
    if (name === "" || email === "" || password === "") {
      toast.error("Fields Should Not Be Empty", {
        theme: "colored",
      });
      setEmailError(true);
      setNameError(true);
      setPasswordError(true);
    } else if (password !== cpassword) {
      toast.error("Password and Confirm Password Does Not Match", {
        theme: "colored",
      });
      setPasswordError(true);
    } else if (emailreg.test(email) === false) {
      toast.error("Email Format is not correct it Contain @ & .com", {
        theme: "colored",
      });
      setEmailError(true);
    } else if (passReg.test(password) === false) {
      toast.error(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters",
        {
          theme: "colored",
        }
      );
      setPasswordError(true);
    } else {
      registrationApi(name, email, password, 1, referralCode, null, (data) => {
        let Userdata = data?.data?.user;
        if (data.status === "success") {
          Swal.fire({
            title: "Success",
            text: data.message,
            icon: "success",
            confirmButtonColor: "#343C5C",
          });
          if (Userdata.role === 4) {
            navigation("/partner-login");
          } else {
            navigation("/startup-login");
          }
        } else if (data.status === "failed") {
          toast.error(data.message, {
            theme: "colored",
          });
        }
      });
    }
  };

  // useEffect(() => {
  //   if (token && getuserDataRedux?.data?.user?.role === "1") {
  //     navigation("/profile");
  //   }
  //   if (token && getuserDataRedux?.data?.user?.role === "4") {
  //     navigation("/Dashboard");
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ToastContainer />
      <TopBar />
      <div className="upper-login">
        <NavBar />
      </div>
      <div className="startup-register-for-login">
        <div className="container">
          <div className="row align-items-center py-sm-0 py-5">
            <div className="col-md-12 d-flex justify-content-center flex-column">
              <div className="row login-slider-show-main">
                <h2 className="text-white text-center py-2">
                  Welcome to modern funding for 21st-century businesses.
                </h2>
                <h1 className="text-white text-center py-0">
                  Looking for funding up to INR 50 crores?
                </h1>
                <h1 className="text-white text-center ">
                  We'll provide a term sheet within 72 hours.
                </h1>
                <div className="col-2"></div>
                <div className="col-md-8 left-start-up-login">
                  <OwlCarousel
                    className="owl-theme"
                    autoplayTimeout={4000}
                    loop
                    nav={true}
                    autoplay={true}
                    items={1}
                    dots={false}
                  >
                    <div className="slideshow">
                      <div className="login-slider-show">
                        <img src="/img/juneja.png" alt="img" />
                        <h5 className="text-center py-2">
                          We are thrilled to announce the successful fundraising
                          round with Vinest. This will enable us to enhance our
                          product range and help our marketing efforts to
                          provide customers with an unmatched shopping
                          experience.
                        </h5>
                        <h2 className="text-center py-2">Garima Juneja</h2>
                        <h3 className="text-center ">
                          Co-Founder and CEO at Praush Beauty (Formerly PLUME)
                        </h3>
                      </div>
                    </div>
                    <div className="slideshow">
                      <div className="login-slider-show">
                        <img src="/img/Akanksha.png" alt="img" />
                        <h5 className="text-center py-2">
                          Raising funds with the invaluable assistance of Vinest
                          has been an exceptionally smooth and efficient
                          journey. In a realm where financial transactions can
                          often be complex and demanding, Vinest's support has
                          truly set a benchmark.
                        </h5>
                        <h2 className="text-center py-2">
                          Akanksha Kanwal Shukla
                        </h2>
                        <h3 className="text-center ">Co-Founder at Beatitude</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
                <div className="col-2"></div>
                <h6 className="text-white text-center py-2">
                  India's leading 200+ businesses have joined the Vinest
                </h6>
                <div className="d-flex justify-content-center my-5">
                  <a
                    href="https://vinest.aicatech.in/"
                    target="_black"
                    className="register-btn"
                  >
                   Get Funded
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <Funding />
      <MiddleSectionFooter />

      <Entrepreneur />
      <BusinessModel />
      <Founder />

      <FooterStartup />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getuserDataRedux: state.user.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userDataRedux: (parameter) => {
      dispatch(setUserData(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStartup);
