import { CATEGORY_ID } from "../type"

const initialState = {
    id: null
}

const categoryIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_ID: return {
            ...state,
            id: action.payload
        }
        default: return state
    }
}

export default categoryIdReducer;