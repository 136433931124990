import React, {useEffect, useState} from 'react'
import '../css/partner.css'

function Partner() {

    const [projectCounter , setProjectCounter] = useState(0)
    const [partnerCounter , setPartnerCounter] = useState(0)

    function numCounter(tagId,maxCount,speed){
        var initialNumber = 0;
        function counter(){
            document.getElementById(tagId).innerHTML = initialNumber;
            ++initialNumber;
            // setProjectCounter(projectCounter+1)
            // setPartnerCounter(partnerCounter+1)
        }
        var counterDelay = setInterval(counter,speed);
        function totalTime(){
            clearInterval(counterDelay);
            document.getElementById("Partners").innerHTML = 200
            document.getElementById("Projects").innerHTML = 100
            // setProjectCounter(100)
            // setPartnerCounter(200)
        }
        var totalPeriod = speed * (maxCount);  
        setTimeout(totalTime, totalPeriod);
    }
    
   useEffect(()=>{
    numCounter("Projects",100,10);
    numCounter("Partners",200,10);
   },[])

  return (
   
   <>
      
   <div className="partner">
   <div className="container">
     <div className="row">
        <div className="col-md-4">
            <div className="partner-main">
              <h2><span id="Partners">0</span>+</h2>
                <p>Startup Registered</p>
            </div>
        </div>
        <div className="col-md-4">
            <div className="partner-main">
                 <h2><span id="Projects">0</span>+</h2>
                <p>Partners</p>
            </div>
        </div>
        <div className="col-md-4">
            <div className="partner-main">
                <h2>Upto 50Cr</h2>
                <p>Financing Limits</p>
            </div>
        </div>
     </div>
   </div>
   </div>
   </>
  )
}

export default Partner
