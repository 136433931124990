import React,{useEffect, useState} from 'react'
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";



function GoogleSearch() {

    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
      } = useGoogle({
        apiKey: 'AIzaSyACtOJ0whgKx6WS-afuGyfYVxsX17T0IZY',
      });
      const [value, setValue] = useState("");



  return (
    <>
     <div style={{ width: "250px" }}>
      <span>Debounced</span>
      <input
        style={{ color: "black" }}
        value={value}
        placeholder="Debounce 500 ms"
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value });
          setValue(evt.target.value);
        }}
        loading={isPlacePredictionsLoading}
        className='form-control'
      />
      <div
        style={{
          width: "200px",
          height: "200px",
          display: "flex",
          flex: "1",
          flexDirection: "column",
          marginBottom: "100px",
        }}
      >
          {!isPlacePredictionsLoading && (
             
                placePredictions.map((item,i)=>{
                    return <li key={i} className='list-map' 
                      style={{
                        listStyle:'none',
                        width:'240px',
                        border:'1px solid grey',
                        padding:'5px',
                        height:'50px'
                      }}
                    >{item.description}</li>
                })
            
          )
            }
       
      </div>
    </div>
  </>
  )
}

export default GoogleSearch
