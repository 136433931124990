import React,{useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../Redux/actions";
import { connect } from "react-redux";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../components/NavBar";
import TopBar from "../components/TopBar";
import {AiFillEye} from 'react-icons/ai'
import {AiFillEyeInvisible} from 'react-icons/ai'
import LoginDetailsPage from "../components/LoginDetailsPage";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import  GoogleLogin  from "./GoogleLogin";
import { getLoginApi } from "../Apis";
import { setUpdateScreen } from "../Redux/actions";
import PartnerBelow from "./PartnerBelow";
import PartnerWelcome from "./PartnerWelcome";
import "../css/startuplogin.css";




let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

function PartnerLogin({userDataRedux,setUpdateScreenRedux,getUpdateScreenRedux}) {
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordShow, setisPasswordShow] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  
  const googleLogin = () => {
    GoogleLogin(4,navigation, (data) => {
      userDataRedux(data);
    });
  };

  const handleLoginButton =()=>{
    if (email === "" || password === "") {
      toast.error("Fields Should Not Be Empty", {
        theme: "colored",
      });
      setEmailError(true);
      setPasswordError(true);
    } else if (emailreg.test(email) === false) {
      toast.error("Email Format is not correct it Contain @ & .com", {
        theme: "colored",
      });
      setEmailError(true);
    } else {
      getLoginApi(email, password,navigation, '4');
      setUpdateScreenRedux(!getUpdateScreenRedux)
    }
  }
  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[])

  return (
    <>
      <ToastContainer />
      <TopBar />
       <div className="upper-login">
       <NavBar />
       </div>
      <div className="startup">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-md-6">
              <div className="row">
                <PartnerWelcome/>
              </div>
            </div>
            <div className="col-md-2"></div>

            <div className="col-md-4">
              <div className="right-start-up-login">
                <div className="form">
                  <h1>Welcome to V!nest</h1>
                  <div className="login-input-single mt-3">
                    <label style={{ color: emailError ? "red" : "#000" }} htmlFor="fname">Email</label>
                    <input
                      style={{
                        color: emailError ? "red" : "#000",
                        borderColor: emailError ? "red" : "rgb(182, 174, 174)",
                      }}
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                    />
                  </div>
                  <div className="login-input-single mt-4">
                    <label style={{ color: passwordError ? "red" : "#000" }} htmlFor="password">Password</label>
                    <input
                      style={{
                        color: passwordError ? "red" : "#000",
                        borderColor: passwordError ? "red" : "rgb(182, 174, 174)",
                      }}
                      type={isPasswordShow?'text':"password"}
                      id="password"
                      name="password"
                      placeholder="********"
                      value={password}
                      onChange={(e)=>setPassword(e.target.value)}
                    />
                    {
                      isPasswordShow
                      ?
                      <AiFillEyeInvisible onClick={()=>setisPasswordShow(!isPasswordShow)} className='eye-icons-login'  />
                      :
                      <AiFillEye onClick={()=>setisPasswordShow(!isPasswordShow)} className='eye-icons-login' />
                    }
                  </div>
                  <p className="forgot-password" onClick={()=>navigation('/forgot-password')} >Forgot Password</p>

                  <div className="login-buttons">
                    <div className="google-login-btn" onClick={googleLogin}>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-9 google-button">
                            <p>Sign in with</p>
                          </div>
                          <div className="col-md-3 google-button">
                            <img src="/Icons/google-icon.png" alt="Image" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      className="login-btn"
                      onClick={handleLoginButton}
                    >
                      Login
                    </button>
                  </div>

                  <p className="dont-account-login">
                    Don’t have an account?{" "}
                    <span onClick={() => navigation("/partner-register")}>
                      Sign-up
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Partner /> */}
      <LoginDetailsPage />
      <PartnerBelow/>
      
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getUpdateScreenRedux:state.update.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userDataRedux: (parameter) => {
      dispatch(setUserData(parameter));
    },
    setUpdateScreenRedux: (parameter) => {
      dispatch(setUpdateScreen(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerLogin);

