import React, { useState, useEffect } from "react";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { AiOutlineBars } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { SlUser } from "react-icons/sl";
import { setUpdateScreen, setUserData } from "../Redux";
import { fetchUserDetails } from "../Apis";
import Swal from "sweetalert2";
import { AiOutlineLogout } from "react-icons/ai";
import { connect } from "react-redux";

import Dropdown from "react-bootstrap/Dropdown";

import "../css/navbar.css";

var token = localStorage.getItem("VinestToken");
function NavBar({
  userData,
  getUpdateScreenRedux,
  setUpdateScreenRedux,
  userDataRedux,
}) {
  const navigation = useNavigate();
  const [companyDropdown, setCompanyDropdown] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMobileDropdown, setShowmobileDropdown] = useState(false);
  useEffect(() => {
    fetchUserDetails((data) => {
      userDataRedux(data);
    });
  }, [getUpdateScreenRedux]);
  return (
    <>
      <div className="container py-4">
        <div className="row">
          <div className="col-md-3 ">
            <div className="logo">
              <img
                src="/img/vinest.png"
                alt="Image"
                onClick={() => navigation("/")}
              />
              {showSidebar ? (
                <RxCross1 onClick={() => setShowSidebar(!showSidebar)} />
              ) : (
                <AiOutlineBars onClick={() => setShowSidebar(!showSidebar)} />
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="center-nav">
              {userData?.data ? null : (
                <ul>
                  <li onClick={() => navigation("/startup-register")}>
                    Startups
                  </li>
                  <li
                    onClick={() => navigation("/investor-login")}
                    className="invest"
                  >
                    Investor
                  </li>
                  <li onClick={() => navigation("/partner-register")}>
                    Become Partner
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="last-nav">
              {userData?.data ? (
                <div className="profile-home-page d-flex flex-column">
          
                  <Dropdown>
                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                      <SlUser style={{ color: "#fff" }} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigation("/profile")}>
                        {userData?.data?.user?.name}{" "}
                        <img
                          onClick={() => navigation("/profile")}
                          src={userData?.data?.user?.image===''?'/img/user-logo.png':userData?.data?.user?.image}
                          alt="user-image"
                        />{" "}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "Do You Want To Logout!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#343C5C",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, logout!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setUpdateScreenRedux(!getUpdateScreenRedux);
                              localStorage.setItem("VinestToken", null);
                              localStorage.removeItem("vinestStartupId");
                              navigation("/");
                              window.location.reload();
                            }
                          });
                        }}
                      >
                        Logout <AiOutlineLogout />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <ul>
                  <li onClick={() => navigation("/")}>Home</li>
                  <li
                    onMouseEnter={() => setCompanyDropdown(true)}
                    onMouseLeave={() => setCompanyDropdown(false)}
                  >
                    Company <MdOutlineArrowDropDown />
                  </li>

                  {companyDropdown ? (
                    <div className="company-dropdown">
                      <p
                        onMouseEnter={() => setCompanyDropdown(true)}
                        onMouseLeave={() => setCompanyDropdown(false)}
                        onClick={() => navigation("/about")}
                      >
                        About Us
                      </p>
                      {/* <p
                        onMouseEnter={() => setCompanyDropdown(true)}
                        onMouseLeave={() => setCompanyDropdown(false)}
                        onClick={() => navigation("/blog")}
                      >
                        Blog
                      </p>
                      <p
                        onMouseEnter={() => setCompanyDropdown(true)}
                        onMouseLeave={() => setCompanyDropdown(false)}
                        onClick={() => navigation("/faq")}
                      >
                        FAQ
                      </p> */}
                    </div>
                  ) : null}

                  <li onClick={() => navigation("/contact")}>Contact</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      {showSidebar ? (
        <div className="center-nav-for-mobile">
          <ul>
            <li onClick={() => navigation("/startup-register")}>Start-ups</li>
            <li onClick={() => navigation("/investor-login")}>Investor</li>
            <li onClick={() => navigation("/partner-register")}>
              Become Partner
            </li>
            <li>Home</li>
            <li onClick={() => setShowmobileDropdown(!showMobileDropdown)}>
              Company <MdOutlineArrowDropDown />
            </li>
            {showMobileDropdown ? (
              <div className="center-nav-dropdown-mobile">
                <p onClick={() => navigation("/about")}>About Us</p>
                <p>Blog</p>
                <p>FAQ</p>
              </div>
            ) : null}

            <li onClick={() => navigation("/contact")}>Contact</li>
          </ul>
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    getUpdateScreenRedux: state.update.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateScreenRedux: (parameter) => {
      dispatch(setUpdateScreen(parameter));
    },
    userDataRedux: (parameter) => {
      dispatch(setUserData(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
