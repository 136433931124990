import React from 'react'
import {FaCheck} from 'react-icons/fa'
import '../css/businessmodel.css'

function BusinessModel() {
  return (
    <div className="business-model-background py-4" >
       <div className="container py-5">
        <div className="row">
            <div className="col-md-4  d-flex justify-content-center align-items-center">
                <div className="model">
                    <p>Business Models</p>
                    <ul>
                        <li><FaCheck/>Subscription-Based Revenue</li>
                        <li><FaCheck/>Contracted Revenue</li>
                        <li><FaCheck/>Predictable Revenue Streams</li>
                    </ul>
                </div>
            </div>
            <div className="col-md-4">
                <div className="business-img">
                    {/* <img src="/Icons/middle-circle.png" alt="" /> */}
                    <p>Who Can Use <span className='business-v' >V<span>!</span>nest</span> 
                     </p>
                </div>
            </div>
            <div className="col-md-4  d-flex justify-content-center align-items-center">
            <div className="model">
                    <p>Eligibility </p>
                    <ul>
                        <li><FaCheck/>1 Cr+ Annual Recurring Revenue</li>
                        <li><FaCheck/>1+ Year Since Incorporation</li>
                        <li><FaCheck/>3+ Months Of Runway</li>
                    </ul>
                </div>
            </div>
        </div>
       </div>
    </div>
  )
}

export default BusinessModel
