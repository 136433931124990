import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import "../css/bannerbottom.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function BannerBottom() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow:1 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];


  return (
    <>
      <div className="banner-bottom d-none">
        <div className="container d-none">
          <div className="row">
            <OwlCarousel
              className="owl-theme"
              autoplayTimeout={2000}
              loop
              margin={10}
              nav
              autoplay={true}
              items={5}
              dots={false}
              responsive={{
                0:{
                    items:2,
                    nav:true
                },
                600:{
                    items:3,
                    nav:false
                },
                1000:{
                    items:5,
                    nav:true,
                    loop:false
                }
            }}
            >
              <div className="item banner-bottom-main">
                <img src="/Icons/logo9.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo2.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo7.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo5.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo4.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo9.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo2.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo7.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo5.png" alt="" />
              </div>
              <div className="item banner-bottom-main">
                <img src="/Icons/logo4.png" alt="" />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>

      {/* <div className="banner-bottom">
        <div className="container-fluid">
          <div className="row">
            <Carousel breakPoints={breakPoints} pagination={false} enableAutoPlay={true} showArrows={false} >
              {data &&
                data.map((item, i) => {
                  return (
                    <div className="banner-bottom-main">
                      <img src={item} alt="Image" />
                    </div>
                  );
                })}
            </Carousel>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default BannerBottom;
