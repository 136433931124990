import React, { useState, useEffect } from "react";
// import DashboardChart from "../dashboards/DashboardChart";
// import DashboardList from "../dashboards/DashboardList";
import DashboardNav from "../dashboards/DasboardNav";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { FormLabel } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import {
  addStartups,
  fetchCategories,
  fetchSingleStartup,
  fetchUserDetails,
  singleFileUploadAddStartup,
} from "../Apis";
import { setCategoryId } from "../Redux";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setSingleStartupId, setUserData } from "../Redux";
import { BsCheck2Circle } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import "../css/startuppage.css";

var token = localStorage.getItem("VinestToken");
const urlPattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

function AddStartupPage({
  setCategoryIdRedux,
  getCategoryIdRedux,
  setSingleStartupIdRedux,
  getSingleStartupIdRedux,
  getuserDataRedux,
  userDataRedux,
  getIsLodaerRedux,
}) {
  const navigation = useNavigate();
  const pathname = window.location.pathname;
  const [categories, setCategories] = useState([]);

  const [isFundingreceived, setIsFundingReceived] = useState("No");
  const [name, setName] = useState("");

  const [companyName, setCompanyName] = useState(""); //
  const [companyWebsite, setCompanyWebsite] = useState(""); //
  const [founderContact, setFounderContact] = useState(""); //
  const [founderEmail, setfounderEmail] = useState(""); //
  const [investment_type, setinvestment_type] = useState(""); //
  const [capital, setCapital] = useState(""); //
  const [founded_year, setfounded_year] = useState(""); //
  const [funding_rec_p_amount, setfunding_rec_p_amount] = useState(""); //

  const [pitchDechFile, setPitchDeckFile] = useState([]);
  const [valuationFile, setValuationFile] = useState([]);

  const [websiteError, setWebsiteError] = useState(false);
  const [contactError, setContactError] = useState(false);

  const [isFounderEmail, setIsFounderEmail] = useState(false);

  const handleStartup = (e) => {
    e.preventDefault();
    if (
      (name === "" ||
        founderEmail === "" ||
        companyName === "" ||
        companyWebsite === "" ||
        founderContact === "",
      investment_type === "",
      capital === "" || isFundingreceived === "")
    ) {
      toast.error("You Have to fill all fields");
    } else if (urlPattern.test(companyWebsite) === false) {
      toast.error("Company Website URL is not correct");
      setWebsiteError(true);
    } else if (founderContact?.length > 10 || founderContact?.length < 10) {
      setContactError(true);
      toast.error("Contact Number should be 10 digit");
    } else {
      const formData = {
        startupId: getSingleStartupIdRedux,
        name: name,
        company_name: companyName,
        company_website: companyWebsite,
        founder_contact: founderContact,
        founder_email: founderEmail,
        investment_type: investment_type,
        capital: capital,
        categories_id: getCategoryIdRedux,
        funding_rec_p_year: isFundingreceived,
        funding_rec_p_amount: funding_rec_p_amount,
        founded_year: founded_year,
        pitchdeck: pitchDechFile,
        valuationReport: valuationFile,
      };
      // console.log(formData, "formDataformDataformData");
      addStartups(formData, navigation, (data) => {
        localStorage.setItem("vinestStartupId", data.data.id);
        setSingleStartupIdRedux(data.data.id);
      });
    }
  };

  useEffect(() => {
    fetchCategories((data) => {
      // console.log(data, "---------------");
      setCategories(data?.data?.categories);
    });
    if (token === null) {
      navigation("/");
    }

    fetchUserDetails((data) => {
      userDataRedux(data);
      setSingleStartupIdRedux(data.data?.user?.startupId);
      if (data?.data?.user?.role === "1") {
        setfounderEmail(data?.data?.user?.email);
        setName(data?.data?.user?.name);
        setIsFounderEmail(true);
      }
    });
  }, [getIsLodaerRedux]);
  return (
    <div>
      <ToastContainer position="top-center" />
      <DashboardNav />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="table-dashboard mt-5">
              <div className="d-flex justify-content-between align-items-center add-startup-back-btn ">
                <h3 className="text-start mt-2 py-3">Basic Details</h3>
                <h4 onClick={() => navigation("/dashboard")} className="">
                  Back
                </h4>
              </div>
              <form onSubmit={(e) => handleStartup(e)}>
                <div className="addstart-up-input d-flex justify-content-between mt-3">
                  <TextField
                    size="small"
                    required
                    id="outlined-required"
                    label="Company Name"
                    sx={{ width: "100%" }}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <TextField
                    size="small"
                    required
                    error={websiteError ? true : false}
                    id="outlined-required"
                    label="Website(eg. www.companyurl.com)"
                    sx={{ width: "100%", marginLeft: 2 }}
                    value={companyWebsite}
                    onChange={(e) => {
                      setCompanyWebsite(e.target.value);
                      setWebsiteError(false);
                    }}
                  />
                </div>
                <div className="addstart-up-input d-flex justify-content-between mt-5">
                  <TextField
                    size="small"
                    required
                    id="outlined-required"
                    label="Founder Name"
                    sx={{ width: "100%" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    disabled={isFounderEmail}
                    type="email"
                    size="small"
                    required
                    id="outlined-required"
                    label="Founder Email"
                    sx={{ width: "100%", marginLeft: 2 }}
                    value={founderEmail}
                    onChange={(e) => setfounderEmail(e.target.value)}
                  />
                </div>
                <div className="addstart-up-input d-flex justify-content-between mt-5">
                  <FormControl
                    size="small"
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormLabel
                      className="text-start"
                      id="demo-radio-buttons-group-label"
                    >
                      Investment
                    </FormLabel>

                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <FormControlLabel
                          value="Equity"
                          control={
                            <Radio
                              sx={{
                                color: "#FE5902",
                                "&.Mui-checked": {
                                  color: "#FE5902",
                                },
                              }}
                              onClick={() => setinvestment_type("Equity")}
                            />
                          }
                          label="Equity"
                        />
                        <FormControlLabel
                          value="Debt"
                          control={
                            <Radio
                              sx={{
                                color: "#FE5902",
                                "&.Mui-checked": {
                                  color: "#FE5902",
                                },
                              }}
                              onClick={() => setinvestment_type("Debt")}
                            />
                          }
                          label="Debt"
                        />
                        <FormControlLabel
                          value="Both"
                          control={
                            <Radio
                              sx={{
                                color: "#FE5902",
                                "&.Mui-checked": {
                                  color: "#FE5902",
                                },
                              }}
                              onClick={() => setinvestment_type("Both")}
                            />
                          }
                          label="Both"
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    error={contactError ? true : false}
                    type="number"
                    size="small"
                    required
                    id="outlined-required"
                    label="Contact Number"
                    sx={{ width: "100%", marginLeft: 2 }}
                    value={founderContact}
                    onChange={(e) => {
                      setFounderContact(e.target.value);
                      setContactError(false);
                      if (e.target.value.length > 10) {
                        setContactError(true);
                      }
                    }}
                  />
                </div>
                <div className="addstart-up-input d-flex justify-content-start mt-3">
                  {investment_type === "Equity" ||
                  investment_type === "Both" ? (
                    <>
                      <div className="upload_document_field">
                        <span>Pitch Deck</span>
                        <label className="mx-2" htmlFor="pitchDechFile">
                          {pitchDechFile?.length > 0 ? (
                            <BsCheck2Circle style={{ color: "green" }} />
                          ) : (
                            <FileUploadIcon />
                          )}
                          <input
                            name="files[]"
                            multiple
                            id="pitchDechFile"
                            type="file"
                            placeholder="something"
                            className="form-control "
                            accept=".pdf"
                            onChange={(event) => {
                              singleFileUploadAddStartup(
                                event.target.files,
                                "pithdeck",
                                event.target.files.length,
                                (data) => {
                                  // console.log(data,'uploaded files')
                                  setPitchDeckFile(data);
                                }
                              );
                            }}
                          />
                        </label>
                        <h6>Only:.pdf</h6>
                      </div>
                      <div className="upload_document_field mx-5">
                        <span>Valuation Report</span>
                        <label className="mx-2" htmlFor="valuationFile">
                          {valuationFile?.length > 0 ? (
                            <BsCheck2Circle style={{ color: "green" }} />
                          ) : (
                            <FileUploadIcon />
                          )}
                          <input
                            multiple
                            name="files[]"
                            id="valuationFile"
                            type="file"
                            placeholder="something"
                            className="form-control "
                            accept=".pdf"
                            onChange={(event) => {
                              singleFileUploadAddStartup(
                                event.target.files,
                                "valuation",
                                event.target.files.length,
                                (data) => {
                                  // console.log(data,'uploaded files')
                                  setValuationFile(data);
                                }
                              );
                            }}
                          />
                        </label>
                        <h6>Only:.pdf</h6>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="addstart-up-input d-flex justify-content-between mt-3">
                  <FormControl sx={{ width: "49%", marginLeft: 0 }}>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Year Founded
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={founded_year}
                      label="Year Founded"
                      onChange={(e) => setfounded_year(e.target.value)}
                      className="text-start"
                    >
                      <MenuItem value={1990}>1990</MenuItem>
                      <MenuItem value={1991}>1991</MenuItem>
                      <MenuItem value={1992}>1992</MenuItem>
                      <MenuItem value={1993}>1993</MenuItem>
                      <MenuItem value={1994}>1994</MenuItem>
                      <MenuItem value={1995}>1995</MenuItem>
                      <MenuItem value={1996}>1996</MenuItem>
                      <MenuItem value={1997}>1997</MenuItem>
                      <MenuItem value={1998}>1998</MenuItem>
                      <MenuItem value={1999}>1999</MenuItem>
                      <MenuItem value={2000}>2000</MenuItem>
                      <MenuItem value={2001}>2001</MenuItem>
                      <MenuItem value={2001}>2002</MenuItem>
                      <MenuItem value={2002}>2003</MenuItem>
                      <MenuItem value={2004}>2004</MenuItem>
                      <MenuItem value={2005}>2005</MenuItem>
                      <MenuItem value={2006}>2006</MenuItem>
                      <MenuItem value={2007}>2007</MenuItem>
                      <MenuItem value={2008}>2008</MenuItem>
                      <MenuItem value={2009}>2009</MenuItem>
                      <MenuItem value={2010}>2010</MenuItem>

                      <MenuItem value={2011}>2011</MenuItem>
                      <MenuItem value={2012}>2012</MenuItem>
                      <MenuItem value={2013}>2013</MenuItem>
                      <MenuItem value={2014}>2014</MenuItem>
                      <MenuItem value={2015}>2015</MenuItem>
                      <MenuItem value={2016}>2016</MenuItem>
                      <MenuItem value={2017}>2017</MenuItem>
                      <MenuItem value={2018}>2018</MenuItem>
                      <MenuItem value={2019}>2019</MenuItem>
                      <MenuItem value={2020}>2020</MenuItem>
                      <MenuItem value={2021}>2021</MenuItem>
                      <MenuItem value={2022}>2022</MenuItem>
                      <MenuItem value={2023}>2023</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <h4 className="additional-detail-text text-start mt-5 py-3">
                  Additional Details About Company
                </h4>

                <div className="addstart-up-input d-flex justify-content-between mt-3">
                  <TextField
                    type="number"
                    size="small"
                    required
                    id="outlined-required"
                    label="Capital Required(In Lakhs)"
                    sx={{ width: "50%", marginRight: 2 }}
                    value={capital}
                    onChange={(e) => setCapital(e.target.value)}
                  />
                  <FormControl sx={{ width: "50%" }}>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Sector*
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //   value={age}
                      label="Sector"
                      //   onChange={handleChange}
                      className="text-start"
                    >
                      {categories &&
                        categories?.map((item, i) => {
                          return (
                            <MenuItem
                              key={i}
                              onClick={() => setCategoryIdRedux(item.id)}
                              value={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="addstart-up-input d-flex justify-content-between mt-5">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Privious Funding Recieved
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={isFundingreceived}
                      label="Previous Funding Recieved"
                      onChange={(e) => {
                        setIsFundingReceived(e.target.value);
                        // console.log(e,'eeeeeeeeeeeee')
                      }}
                      className="text-start"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ width: "100%", marginLeft: 2 }}>
                    {isFundingreceived === "Yes" ? (
                      <TextField
                        type="number"
                        size="small"
                        required
                        id="outlined-required"
                        label="Enter Amount(lakhs)"
                        sx={{ width: "100%" }}
                        value={funding_rec_p_amount}
                        onChange={(e) =>
                          setfunding_rec_p_amount(e.target.value)
                        }
                      />
                    ) : null}
                  </Box>
                </div>

                <div className="submit-button">
                  <button type="submit">Submit Details</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getCategoryIdRedux: state.categoryId.id,
    getSingleStartupIdRedux: state.singleStartupId.id,
    getuserDataRedux: state.user.data,
    getIsLodaerRedux: state.isLoader.isLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCategoryIdRedux: (parameter) => {
      dispatch(setCategoryId(parameter));
    },
    setSingleStartupIdRedux: (parameter) => {
      dispatch(setSingleStartupId(parameter));
    },
    userDataRedux: (parameter) => {
      dispatch(setUserData(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStartupPage);
