import React,{useEffect, useState} from 'react'
import {MdLocationOn} from 'react-icons/md'
import {AiFillPhone} from 'react-icons/ai'
import {FaFax} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { AddContactFormApi } from '../Apis'
import './../css/contact.css'


function Contact() {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[])

    const handleSubmit =()=>{
      let formdata ={
        name:name,
        email:email,
        phone:phone,
        message:message
      }
      AddContactFormApi(formdata, setEmail, setPhone, setName, setMessage)
    }
  return (
    <div className='contact_us_main'>
    <NavBar/>
     <div className="container my-4">
       <div className="row justify-content-center">
            <div className="col-md-3">
                <div className='contact-card-box' >
                    <MdLocationOn/>
                    <h3 className='text-center' >OUR MAIN OFFICE</h3>
                    <h6 className='text-center'>Nirmal Tower Barakhamba Rd, New Delhi, Delhi 110001 </h6>
                </div>
            </div>
            <div className="col-md-3">
            <div className='contact-card-box' >
                    <AiFillPhone/>
                    <h3 className='text-center'>PHONE NUMBER</h3>
                    <h6 className='text-center m-0'>+91 9599912022,</h6>
                    <h6> +91 9953966893</h6>
                </div>
            </div>
            <div className="col-md-3">
            <div className='contact-card-box' >
                    <MdEmail/>
                    <h3 className='text-center'>EMAIL</h3>
                    <h6 className='text-center'>pushkar@vinest.in, shubham@vinest.in</h6>
                </div>
            </div>
       </div>
       <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="contact-form-box">
                <h2>Contact Us</h2>
                <div>
                <input type="text" placeholder='Enter Your Name' value={name} onChange={(e)=>setName(e.target.value)} />
                </div>
                <div>
                <input type="number" placeholder='Enter Your Phone Number'  value={phone} onChange={(e)=>{
                  if(e.target.value.length>10){

                  }else{
                    setPhone(e.target.value)
                  }
                }} />
                </div>
                <div>
                <input type="email" placeholder='Enter a valid email address' value={email} onChange={(e)=>setEmail(e.target.value)} />
                </div>
                <textarea placeholder='Message' value={message} onChange={(e)=>setMessage(e.target.value)} id="w3review" name="w3review" rows="4" cols="50"></textarea>
                <button onClick={handleSubmit} >Submit</button>
              </div>
          </div>
          <div className="col-md-3"></div>
       </div>
         
     </div>
     <Footer/>
    </div>
  )
}

export default Contact
