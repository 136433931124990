import React, { useState, useEffect } from "react";
import DasboardNav from "../dashboards/DasboardNav";
import Table from "react-bootstrap/Table";
import { fetchSingleStartupForEdit,EditaddStartupFinancial } from "../Apis";
import { fetchCategories } from "../Apis";
import { setLoader } from "../Redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./../css/editdetails.css";

function EditFinancialDetails({setIsLoaderRedux}) {
    const navigation =useNavigate()
  const pathID = window.location.pathname.split("/")[2];
  const [userData, setUserData] = useState();

  const [arrcurrent, setarrcurrent] = useState("");
  const [arrfirstprevious, setarrfirstprevious] = useState("");
  const [arrsecondprevious, setarrsecondprevious] = useState("");

  const [cogscurrent, setcogscurrent] = useState("");
  const [cogsfirstprevious, setcogsfirstprevious] = useState("");
  const [cogssecondprevious, setcogssecondprevious] = useState("");

  const [channeldiscountcurrent, setchanneldiscountcurrent] = useState("");
  const [channeldiscountfirstprevious, setchanneldiscountfirstprevious] =
    useState("");
  const [channeldiscountsecondprevious, setchanneldiscountsecondprevious] =
    useState("");

  const [shippingcostcurrent, setshippingcostcurrent] = useState("");
  const [shippingcostfirstprevious, setshippingcostfirstprevious] =
    useState("");
  const [shippingcostsecondprevious, setshippingcostsecondprevious] =
    useState("");

  const [onlineaddcurrent, setonlineaddcurrent] = useState("");
  const [onlineaddfirstprevious, setonlineaddfirstprevious] = useState("");
  const [onlineaddsecondprevious, setonlineaddsecondprevious] = useState("");

  const [brandpromotioncurrent, setbrandpromotioncurrent] = useState("");
  const [brandpromotionfirstprevious, setbrandpromotionfirstprevious] =
    useState("");
  const [brandpromotionsecondprevious, setbrandpromotionsecondprevious] =
    useState("");

  const [online_pa_cocurrent, setonline_pa_cocurrent] = useState("");
  const [online_pa_cofirstprevious, setonline_pa_cofirstprevious] =
    useState("");
  const [online_pa_cosecondprevious, setonline_pa_cosecondprevious] =
    useState("");

  const [interest_paymentcurrent, setinterest_paymentcurrent] = useState("");
  const [interest_paymentfirstprevious, setinterest_paymentfirstprevious] =
    useState("");
  const [interest_paymentsecondprevious, setinterest_paymentsecondprevious] =
    useState("");

  const [emicurrent, setemicurrent] = useState("");
  const [emifirstprevious, setemifirstprevious] = useState("");
  const [emisecondprevious, setemisecondprevious] = useState("");

  const [way_on_datecurrent, setway_on_datecurrent] = useState("");
  const [way_on_datefirstprevious, setway_on_datefirstprevious] = useState("");
  const [way_on_datesecondprevious, setway_on_datesecondprevious] =
    useState("");

  const [expense_to_revenuecurrent, setexpense_to_revenuecurrent] = useState('');
  const [expense_to_revenuefirstprevious, setexpense_to_revenuefirstprevious] =
    useState();
  const [
    expense_to_revenuesecondprevious,
    setexpense_to_revenuesecondprevious,
  ] = useState();

  useEffect(() => {
    fetchSingleStartupForEdit(pathID, setIsLoaderRedux,(data) => {
      setUserData(data);
      let financialData = data?.financial[0];

      setarrcurrent(financialData?.arr?.current_fy);
      setarrfirstprevious(financialData?.arr?.first_pervious_fy);
      setarrsecondprevious(financialData?.arr?.second_pervious_fy);

      setcogscurrent(financialData?.cogs?.current_fy);
      setcogsfirstprevious(financialData?.cogs?.first_pervious_fy);
      setcogssecondprevious(financialData?.cogs?.second_pervious_fy);

      setchanneldiscountcurrent(financialData?.channel_discount?.current_fy);
      setchanneldiscountfirstprevious(
        financialData?.channel_discount?.first_pervious_fy
      );
      setchanneldiscountsecondprevious(
        financialData?.channel_discount?.second_pervious_fy
      );

      setshippingcostcurrent(financialData?.shipping_cost?.current_fy);
      setshippingcostfirstprevious(
        financialData?.shipping_cost?.first_pervious_fy
      );
      setshippingcostsecondprevious(
        financialData?.shipping_cost?.second_pervious_fy
      );

      setonlineaddcurrent(financialData?.online_add?.current_fy);
      setonlineaddfirstprevious(financialData?.online_add?.first_pervious_fy);
      setonlineaddsecondprevious(financialData?.online_add?.second_pervious_fy);

      setbrandpromotioncurrent(financialData?.brand_promotion?.current_fy);
      setbrandpromotionfirstprevious(
        financialData?.brand_promotion?.first_pervious_fy
      );
      setbrandpromotionsecondprevious(
        financialData?.brand_promotion?.second_pervious_fy
      );

      setonline_pa_cocurrent(financialData?.online_pa_co?.current_fy);
      setonline_pa_cofirstprevious(
        financialData?.online_pa_co?.first_pervious_fy
      );
      setonline_pa_cosecondprevious(
        financialData?.online_pa_co?.second_pervious_fy
      );

      setinterest_paymentcurrent(financialData?.interest_payment?.current_fy);
      setinterest_paymentfirstprevious(
        financialData?.interest_payment?.first_pervious_fy
      );
      setinterest_paymentsecondprevious(
        financialData?.interest_payment?.second_pervious_fy
      );

      setemicurrent(financialData?.emi?.current_fy);
      setemifirstprevious(financialData?.emi?.first_pervious_fy);
      setemisecondprevious(financialData?.emi?.second_pervious_fy);

      setway_on_datecurrent(financialData?.way_on_date?.current_fy);
      setway_on_datefirstprevious(
        financialData?.way_on_date?.first_pervious_fy
      );
      setway_on_datesecondprevious(
        financialData?.way_on_date?.second_pervious_fy
      );

      setexpense_to_revenuecurrent(cogscurrent + channeldiscountcurrent + shippingcostcurrent +onlineaddcurrent + brandpromotioncurrent + online_pa_cocurrent)
      setexpense_to_revenuefirstprevious(cogsfirstprevious + channeldiscountfirstprevious+ shippingcostfirstprevious + online_pa_cofirstprevious+onlineaddfirstprevious + brandpromotionfirstprevious)
      setexpense_to_revenuesecondprevious(cogssecondprevious + channeldiscountsecondprevious + shippingcostsecondprevious+ onlineaddsecondprevious+ brandpromotionsecondprevious+ online_pa_cosecondprevious)
    });
  }, []);

  const handleSubmit = () => {


    let formData = {
      id: pathID,
      arr: {
        current_fy: arrcurrent,
        first_pervious_fy: arrfirstprevious,
        second_pervious_fy: arrsecondprevious,
      },
      cogs: {
        current_fy: cogscurrent,
        first_pervious_fy: cogsfirstprevious,
        second_pervious_fy: cogssecondprevious,
      },

      channel_discount: {
        current_fy: channeldiscountcurrent,
        first_pervious_fy: channeldiscountfirstprevious,
        second_pervious_fy: channeldiscountsecondprevious,
      },
      shipping_cost: {
        current_fy: shippingcostcurrent,
        first_pervious_fy: shippingcostfirstprevious,
        second_pervious_fy: shippingcostsecondprevious,
      },
      online_add: {
        current_fy: onlineaddcurrent,
        first_pervious_fy: onlineaddfirstprevious,
        second_pervious_fy: onlineaddsecondprevious,
      },
      brand_promotion: {
        current_fy: brandpromotioncurrent,
        first_pervious_fy: brandpromotionfirstprevious,
        second_pervious_fy: brandpromotionsecondprevious,
      },
      online_pa_co: {
        current_fy: online_pa_cocurrent,
        first_pervious_fy: online_pa_cofirstprevious,
        second_pervious_fy: online_pa_cosecondprevious,
      },
      interest_payment: {
        current_fy: interest_paymentcurrent,
        first_pervious_fy: interest_paymentfirstprevious,
        second_pervious_fy: interest_paymentsecondprevious,
      },
      emi: {
        current_fy: emicurrent,
        first_pervious_fy: emifirstprevious,
        second_pervious_fy: emisecondprevious,
      },
      way_on_date: {
        current_fy: way_on_datecurrent,
        first_pervious_fy: way_on_datefirstprevious,
        second_pervious_fy: way_on_datesecondprevious,
      },
      expense_to_revenue: {
        current_fy:
          cogscurrent +
          channeldiscountcurrent +
          shippingcostcurrent +
          onlineaddcurrent +
          brandpromotioncurrent +
          online_pa_cocurrent,
        first_pervious_fy:
          cogsfirstprevious +
          channeldiscountfirstprevious +
          shippingcostfirstprevious +
          onlineaddfirstprevious +
          brandpromotionfirstprevious +
          online_pa_cofirstprevious,
        second_pervious_fy:
          cogssecondprevious +
          channeldiscountsecondprevious +
          shippingcostsecondprevious +
          onlineaddsecondprevious +
          brandpromotionsecondprevious +
          online_pa_cosecondprevious,
      },
    };
    EditaddStartupFinancial(formData, navigation);
  };

  return (
    <div className="container">
      <h4 className="text-start mt-4 fw-bold">Financial Details</h4>
      <div className="row">
        <Table responsive="lg">
          <thead className="table-main-upload">
            <tr>
              <th></th>
              <th>Current FS Year</th>
              <th>FY22-23</th>
              <th>FY21-22</th>
            </tr>
          </thead>
          <tbody className="table-main-upload-body">
            <tr>
              <td>ARR</td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={arrcurrent}
                  onChange={(e) => setarrcurrent(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={arrfirstprevious}
                  onChange={(e) => setarrfirstprevious(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={arrsecondprevious}
                  onChange={(e) => setarrsecondprevious(e.target.value)}
                />
              </td>
            </tr>

            <tr>
              <td className="ExpenseClass">Expense to Revenue</td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                    value={ cogscurrent +
                        channeldiscountcurrent +
                        shippingcostcurrent +
                        onlineaddcurrent +
                        brandpromotioncurrent +
                        online_pa_cocurrent}
                />
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                    value={ cogsfirstprevious +
                        channeldiscountfirstprevious +
                        shippingcostfirstprevious +
                        onlineaddfirstprevious +
                        brandpromotionfirstprevious +
                        online_pa_cofirstprevious}
                />
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={ cogssecondprevious +
                    channeldiscountsecondprevious +
                    shippingcostsecondprevious +
                    onlineaddsecondprevious +
                    brandpromotionsecondprevious +
                    online_pa_cosecondprevious}
                />
              </td>
            </tr>
            <>
              <tr>
                <td className="sub_heading_table">COGS</td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={cogscurrent}
                    onChange={(e) => setcogscurrent(parseInt(e.target.value))}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={cogsfirstprevious}
                    onChange={(e) => setcogsfirstprevious(parseInt(e.target.value))}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={cogssecondprevious}
                    onChange={(e) => setcogssecondprevious(parseInt(e.target.value))}
                  />
                </td>
              </tr>
              <tr>
                <td className="sub_heading_table">Channel Discounts</td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={channeldiscountcurrent}
                    onChange={(e) => setchanneldiscountcurrent(parseInt(e.target.value))}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={channeldiscountfirstprevious}
                    onChange={(e) =>
                      setchanneldiscountfirstprevious(parseInt(e.target.value))
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={channeldiscountsecondprevious}
                    onChange={(e) =>
                      setchanneldiscountsecondprevious(parseInt(e.target.value))
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="sub_heading_table">Shipping Costs</td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={shippingcostcurrent}
                    onChange={(e) => setshippingcostcurrent(parseInt(e.target.value))}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={shippingcostfirstprevious}
                    onChange={(e) =>
                      setshippingcostfirstprevious(parseInt(e.target.value))
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={shippingcostsecondprevious}
                    onChange={(e) =>
                      setshippingcostsecondprevious(parseInt(e.target.value))
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="sub_heading_table">Online Advertisement</td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={onlineaddcurrent}
                    onChange={(e) => setonlineaddcurrent(parseInt(e.target.value))}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={onlineaddfirstprevious}
                    onChange={(e) => setonlineaddfirstprevious(parseInt(e.target.value))}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={onlineaddsecondprevious}
                    onChange={(e) => setonlineaddsecondprevious(parseInt(e.target.value))}
                  />
                </td>
              </tr>
              <tr>
                <td className="sub_heading_table"> Brand Promotion</td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={brandpromotioncurrent}
                    onChange={(e) => setbrandpromotioncurrent(parseInt(e.target.value))}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={brandpromotionfirstprevious}
                    onChange={(e) =>
                      setbrandpromotionfirstprevious(parseInt(e.target.value))
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={brandpromotionsecondprevious}
                    onChange={(e) =>
                      setbrandpromotionsecondprevious(parseInt(e.target.value))
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="sub_heading_table">Online Partner Commission</td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={online_pa_cocurrent}
                    onChange={(e) => setonline_pa_cocurrent(parseInt(e.target.value))}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={online_pa_cofirstprevious}
                    onChange={(e) =>
                      setonline_pa_cofirstprevious(parseInt(e.target.value))
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="form-control "
                    value={online_pa_cosecondprevious}
                    onChange={(e) =>
                      setonline_pa_cosecondprevious(parseInt(e.target.value))
                    }
                  />
                </td>
              </tr>
            </>

            <tr>
              <td>Interest Payment</td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={interest_paymentcurrent}
                  onChange={(e) => setinterest_paymentcurrent(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={interest_paymentfirstprevious}
                  onChange={(e) =>
                    setinterest_paymentfirstprevious(e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={interest_paymentsecondprevious}
                  onChange={(e) =>
                    setinterest_paymentsecondprevious(e.target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <td>EMI</td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={emicurrent}
                  onChange={(e) => setemicurrent(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={emifirstprevious}
                  onChange={(e) => setemifirstprevious(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control "
                  value={emisecondprevious}
                  onChange={(e) => setemisecondprevious(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Run Way as on Date</td>
              <td>
                <select
                  value={way_on_datecurrent}
                  onChange={(e) => setway_on_datecurrent(e.target.value)}
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">2</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </td>
              <td>
                <select
                  value={way_on_datefirstprevious}
                  onChange={(e) => setway_on_datefirstprevious(e.target.value)}
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">2</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </td>
              <td>
                <select
                  value={way_on_datesecondprevious}
                  onChange={(e) => setway_on_datesecondprevious(e.target.value)}
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">2</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="edit-info-btn">
        <button onClick={handleSubmit} >UPDATE</button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    getIsLodaerRedux: state.isLoader.isLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoaderRedux: (parameter) => {
      dispatch(setLoader(parameter));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFinancialDetails);

