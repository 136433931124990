import React, { useState, useEffect } from "react";
import { MdOutlineArrowDropDown } from "react-icons/md";
import DashboardNav from "../dashboards/DasboardNav";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { addStartupFinancial } from "../Apis";
import { ToastContainer, toast } from "react-toastify";
import { fetchSingleStartup } from "../Apis";
import { setCategoryId } from "../Redux";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "../css/startuppage.css";

var startupId = localStorage.getItem("vinestStartupId");
function UploadDocumentStartupPage({
  getCategoryIdRedux,
  getuserDataRedux,
  getSingleStartupIdRedux,
}) {
  const navigation = useNavigate();
  const pathname = window.location.pathname;
  const [revenueDropdown, setRevenueDropdown] = useState(false);
  const [arrcurrent, setarrcurrent] = useState("");
  const [arrfirstprevious, setarrfirstprevious] = useState("");
  const [arrsecondprevious, setarrsecondprevious] = useState("");

  const [cogscurrent, setcogscurrent] = useState("");
  const [cogsfirstprevious, setcogsfirstprevious] = useState("");
  const [cogssecondprevious, setcogssecondprevious] = useState("");

  const [channeldiscountcurrent, setchanneldiscountcurrent] = useState("");
  const [channeldiscountfirstprevious, setchanneldiscountfirstprevious] =
    useState("");
  const [channeldiscountsecondprevious, setchanneldiscountsecondprevious] =
    useState("");

  const [shippingcostcurrent, setshippingcostcurrent] = useState("");
  const [shippingcostfirstprevious, setshippingcostfirstprevious] =
    useState("");
  const [shippingcostsecondprevious, setshippingcostsecondprevious] =
    useState("");

  const [onlineaddcurrent, setonlineaddcurrent] = useState("");
  const [onlineaddfirstprevious, setonlineaddfirstprevious] = useState("");
  const [onlineaddsecondprevious, setonlineaddsecondprevious] = useState("");

  const [brandpromotioncurrent, setbrandpromotioncurrent] = useState("");
  const [brandpromotionfirstprevious, setbrandpromotionfirstprevious] =
    useState("");
  const [brandpromotionsecondprevious, setbrandpromotionsecondprevious] =
    useState("");

  const [online_pa_cocurrent, setonline_pa_cocurrent] = useState("");
  const [online_pa_cofirstprevious, setonline_pa_cofirstprevious] =
    useState("");
  const [online_pa_cosecondprevious, setonline_pa_cosecondprevious] =
    useState("");

  const [interest_paymentcurrent, setinterest_paymentcurrent] = useState("");
  const [interest_paymentfirstprevious, setinterest_paymentfirstprevious] =
    useState("");
  const [interest_paymentsecondprevious, setinterest_paymentsecondprevious] =
    useState("");

  const [emicurrent, setemicurrent] = useState("");
  const [emifirstprevious, setemifirstprevious] = useState("");
  const [emisecondprevious, setemisecondprevious] = useState("");

  const [way_on_datecurrent, setway_on_datecurrent] = useState("");
  const [way_on_datefirstprevious, setway_on_datefirstprevious] = useState("");
  const [way_on_datesecondprevious, setway_on_datesecondprevious] =
    useState("");

  const [expense_to_revenuecurrent, setexpense_to_revenuecurrent] = useState(
    []
  );
  const [expense_to_revenuefirstprevious, setexpense_to_revenuefirstprevious] =
    useState();
  const [
    expense_to_revenuesecondprevious,
    setexpense_to_revenuesecondprevious,
  ] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = {
      id: getSingleStartupIdRedux ? getSingleStartupIdRedux : startupId,
      arr: {
        current_fy: arrcurrent,
        first_pervious_fy: arrfirstprevious,
        second_pervious_fy: arrsecondprevious,
      },
      cogs: {
        current_fy: cogscurrent,
        first_pervious_fy: cogsfirstprevious,
        second_pervious_fy: cogssecondprevious,
      },

      channel_discount: {
        current_fy: channeldiscountcurrent,
        first_pervious_fy: channeldiscountfirstprevious,
        second_pervious_fy: channeldiscountsecondprevious,
      },
      shipping_cost: {
        current_fy: shippingcostcurrent,
        first_pervious_fy: shippingcostfirstprevious,
        second_pervious_fy: shippingcostsecondprevious,
      },
      online_add: {
        current_fy: onlineaddcurrent,
        first_pervious_fy: onlineaddfirstprevious,
        second_pervious_fy: onlineaddsecondprevious,
      },
      brand_promotion: {
        current_fy: brandpromotioncurrent,
        first_pervious_fy: brandpromotionfirstprevious,
        second_pervious_fy: brandpromotionsecondprevious,
      },
      online_pa_co: {
        current_fy: online_pa_cocurrent,
        first_pervious_fy: online_pa_cofirstprevious,
        second_pervious_fy: online_pa_cosecondprevious,
      },
      interest_payment: {
        current_fy: interest_paymentcurrent,
        first_pervious_fy: interest_paymentfirstprevious,
        second_pervious_fy: interest_paymentsecondprevious,
      },
      emi: {
        current_fy: emicurrent,
        first_pervious_fy: emifirstprevious,
        second_pervious_fy: emisecondprevious,
      },
      way_on_date: {
        current_fy: way_on_datecurrent,
        first_pervious_fy: way_on_datefirstprevious,
        second_pervious_fy: way_on_datesecondprevious,
      },
      expense_to_revenue: {
        current_fy:
          cogscurrent +
          channeldiscountcurrent +
          shippingcostcurrent +
          onlineaddcurrent +
          brandpromotioncurrent +
          online_pa_cocurrent,
        first_pervious_fy:
          cogsfirstprevious +
          channeldiscountfirstprevious +
          shippingcostfirstprevious +
          onlineaddfirstprevious +
          brandpromotionfirstprevious +
          online_pa_cofirstprevious,
        second_pervious_fy:
          cogssecondprevious +
          channeldiscountsecondprevious +
          shippingcostsecondprevious +
          onlineaddsecondprevious +
          brandpromotionsecondprevious +
          online_pa_cosecondprevious,
      },
    };
    addStartupFinancial(formData, navigation);
  };

  useEffect(() => {
    fetchSingleStartup(getSingleStartupIdRedux, (data) => {
      // console.log(data, "startup data fetch");
      setCategoryId(data.categories_id);
    });
  }, []);

  return (
    <div>
      <ToastContainer position="top-center" />
      <DashboardNav />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="table-dashboard mt-5">
              <div className="d-flex justify-content-start align-items-center ">
                <button className="upload-document-btn">
                  Financial Details
                </button>
              </div>

              <div className="table-uploade py-5">
                <Table responsive="lg">
                  <thead className="table-main-upload">
                    <tr>
                      <th></th>
                      <th>Current FS Year</th>
                      <th>FY22-23</th>
                      <th>FY21-22</th>
                    </tr>
                  </thead>
                  <tbody className="table-main-upload-body">
                    <tr>
                      <td>ARR</td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={arrcurrent}
                          onChange={(e) => setarrcurrent(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={arrfirstprevious}
                          onChange={(e) => setarrfirstprevious(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={arrsecondprevious}
                          onChange={(e) => setarrsecondprevious(e.target.value)}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td
                        className="ExpenseClass"
                        onClick={() => setRevenueDropdown(!revenueDropdown)}
                      >
                        Expense to Revenue
                        <MdOutlineArrowDropDown />
                      </td>
                      <td>
                        <input
                        disabled
                          type="number"
                          className="form-control "
                          value={
                            cogscurrent +
                            channeldiscountcurrent +
                            shippingcostcurrent +
                            onlineaddcurrent +
                            brandpromotioncurrent +
                            online_pa_cocurrent
                          }
                          // onChange={(e)=>setcogscurrent(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                        disabled
                          type="number"
                          value={
                            cogsfirstprevious +
                            channeldiscountfirstprevious +
                            shippingcostfirstprevious +
                            onlineaddfirstprevious +
                            brandpromotionfirstprevious +
                            online_pa_cofirstprevious
                          }
                          className="form-control "
                        />
                      </td>
                      <td>
                        <input
                        disabled
                          type="number"
                          value={
                            cogssecondprevious +
                            channeldiscountsecondprevious +
                            shippingcostsecondprevious +
                            onlineaddsecondprevious +
                            brandpromotionsecondprevious +
                            online_pa_cosecondprevious
                          }
                          className="form-control "
                        />
                      </td>
                    </tr>

                    {revenueDropdown ? (
                      <>
                        <tr>
                          <td className="sub_heading_table">COGS</td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={cogscurrent}
                              onChange={(e) => {
                                setcogscurrent(parseInt(e.target.value));
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={cogsfirstprevious}
                              onChange={(e) =>
                                setcogsfirstprevious(parseInt(e.target.value))
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={cogssecondprevious}
                              onChange={(e) =>
                                setcogssecondprevious(parseInt(e.target.value))
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="sub_heading_table">
                            Channel Discounts
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={channeldiscountcurrent}
                              onChange={(e) => {
                                setchanneldiscountcurrent(
                                  parseInt(e.target.value)
                                );
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={channeldiscountfirstprevious}
                              onChange={(e) =>
                                setchanneldiscountfirstprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={channeldiscountsecondprevious}
                              onChange={(e) =>
                                setchanneldiscountsecondprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="sub_heading_table">Shipping Costs</td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={shippingcostcurrent}
                              onChange={(e) => {
                                setshippingcostcurrent(
                                  parseInt(e.target.value)
                                );
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={shippingcostfirstprevious}
                              onChange={(e) =>
                                setshippingcostfirstprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={shippingcostsecondprevious}
                              onChange={(e) =>
                                setshippingcostsecondprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="sub_heading_table">
                            Online Advertisement
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={onlineaddcurrent}
                              onChange={(e) => {
                                setonlineaddcurrent(parseInt(e.target.value));
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={onlineaddfirstprevious}
                              onChange={(e) =>
                                setonlineaddfirstprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={onlineaddsecondprevious}
                              onChange={(e) =>
                                setonlineaddsecondprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="sub_heading_table">
                            {" "}
                            Brand Promotion
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={brandpromotioncurrent}
                              onChange={(e) =>
                                setbrandpromotioncurrent(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={brandpromotionfirstprevious}
                              onChange={(e) =>
                                setbrandpromotionfirstprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={brandpromotionsecondprevious}
                              onChange={(e) =>
                                setbrandpromotionsecondprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="sub_heading_table">
                            Online Partner Commission
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={online_pa_cocurrent}
                              onChange={(e) =>
                                setonline_pa_cocurrent(parseInt(e.target.value))
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={online_pa_cofirstprevious}
                              onChange={(e) =>
                                setonline_pa_cofirstprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control "
                              value={online_pa_cosecondprevious}
                              onChange={(e) =>
                                setonline_pa_cosecondprevious(
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </td>
                        </tr>
                      </>
                    ) : null}

                    <tr>
                      <td>Interest Payment</td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={interest_paymentcurrent}
                          onChange={(e) =>
                            setinterest_paymentcurrent(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={interest_paymentfirstprevious}
                          onChange={(e) =>
                            setinterest_paymentfirstprevious(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={interest_paymentsecondprevious}
                          onChange={(e) =>
                            setinterest_paymentsecondprevious(e.target.value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>EMI</td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={emicurrent}
                          onChange={(e) => setemicurrent(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={emifirstprevious}
                          onChange={(e) => setemifirstprevious(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control "
                          value={emisecondprevious}
                          onChange={(e) => setemisecondprevious(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Run Way as on Date</td>
                      <td>
                        <div>
                          <select
                            value={way_on_datecurrent}
                            onChange={(e) =>
                              setway_on_datecurrent(e.target.value)
                            }
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">2</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div>
                          <select
                            value={way_on_datefirstprevious}
                            onChange={(e) =>
                              setway_on_datefirstprevious(e.target.value)
                            }
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">2</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div>
                          <select
                            value={way_on_datesecondprevious}
                            onChange={(e) =>
                              setway_on_datesecondprevious(e.target.value)
                            }
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">2</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="next-button-uploaded">
                <button onClick={(e) => handleSubmit(e)}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getCategoryIdRedux: state.categoryId.id,
    getSingleStartupIdRedux: state.singleStartupId.id,
    getuserDataRedux: state.user.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCategoryIdRedux: (parameter) => {
      dispatch(setCategoryId(parameter));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadDocumentStartupPage);
