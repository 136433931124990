import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import '../cssdashboard/index.css'


const { faker } = require('@faker-js/faker');

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: '#FE5902',
    },
    {
      label: 'Dataset 2',
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: '#343C5C',
    },
  ],
};

function DashboardChart() {
  return (
    <div>
       <Bar options={options} data={data} />
    </div>
  )
}

export default DashboardChart
