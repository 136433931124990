import React, { useEffect, useState } from "react";
import DashboardNav from "./DasboardNav";
import DashboardPage from "../dashboards/DashboardPage";
import AddStartupPage from "../pages/AddStartupPage";
import FilledInfoModal from '../modals/FilledInfoModal'
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import "../cssdashboard/index.css";

var vinestInfoModal = localStorage.getItem("vinestInfoModal");
function Dashboard({ getuserDataRedux, getIsLodaerRedux ,getUpdateScreenRedux}) {
  const navigation = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [forReloadDashboardTable, setForReloadDashboardTable] = useState(false);
  useEffect(() => {
    setForReloadDashboardTable(!forReloadDashboardTable);

    if (getuserDataRedux?.data?.user?.role === "1") {
      navigation("/profile");
    }

    if(vinestInfoModal==='false' || vinestInfoModal===false){
      setOpenModal(false)
    }
    if(vinestInfoModal==='true' || vinestInfoModal===true){
      setOpenModal(true)
    }
 
  }, [getUpdateScreenRedux]);

  
  return (
    <>
    <FilledInfoModal 
      setOpenModal={setOpenModal}
      openModal={openModal}
     />

      {getIsLodaerRedux ? (
        <div className="loader-spinner-main">
          <CircularProgress className="loader-spinner" color="success" />
        </div>
      ) : null}
      <DashboardNav />
      <div className="container">
        <DashboardPage forReloadDashboardTable={forReloadDashboardTable} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getuserDataRedux: state.user.data,
    getIsLodaerRedux: state.isLoader.isLoader,
    getUpdateScreenRedux:state.update.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
