import React, { useState, useEffect } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchSingleStartupForEdit,
  EditStartupsApi,
  EditSingleFileUploadAddStartup,
} from "../Apis";
import { fetchCategories } from "../Apis";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { setLoader } from "../Redux";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import "./../css/editdetails.css";

const urlPattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

function EditBasicDetails({getIsLodaerRedux, setIsLoaderRedux}) {
  const pathID = window.location.pathname.split("/")[2];

  const [updatePage, setUpdatePage] = useState(false)
  
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState();
  const [companyWebsite, setCompanyWebsite] = useState();
  const [isFundingreceived, setIsFundingReceived] = useState();
  const [companyName, setCompanyName] = useState();
  const [founderContact, setFounderContact] = useState();
  const [founderEmail, setfounderEmail] = useState("");
  const [investment_type, setinvestment_type] = useState();
  const [capital, setCapital] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [founded_year, setfounded_year] = useState();
  const [funding_rec_p_amount, setfunding_rec_p_amount] = useState();

  const [categoryID, setCategoryID] = useState(null);

  const [pitch, setPitch] = useState();
  const [valuation, setValuation] = useState();

  const [pitchEdit, setPitchEdit] = useState([]);
  const [valuationEdit, setValuationEdit] = useState([]);

  const [pitchLoader, setPitchLoader] = useState(false);
  const [valuationLoader, setValuationLoader] = useState(false);

  const [websiteError, setWebsiteError] = useState(false);
  const [contactError, setContactError] = useState(false);

  useEffect(() => {
    fetchSingleStartupForEdit(pathID,setIsLoaderRedux, (data) => {

      setName(data?.name);
      setCompanyWebsite(data?.company_website);
      setCompanyName(data?.company_name);
      setIsFundingReceived(data?.funding_rec_p_year);
      setfounderEmail(data?.founder_email);
      setinvestment_type(data?.investment_type);
      setFounderContact(data?.founder_contact);
      setCapital(data?.capital);
      setfounded_year(data?.founded_year);
      setfunding_rec_p_amount(data?.funding_rec_p_amount);
      setSelectedCategory(data?.categoryName);
      setCategoryID(data?.categories_id);
      setValuation(data?.valuationReport);
      setPitch(data?.pitchdeck);
    });

    fetchCategories((data) => {
      setCategories(data?.data?.categories);
    });
  }, [updatePage]);

  const onUpdateBasicDetails = (e) => {
    if (
      (name === "" ||
        founderEmail === "" ||
        companyName === "" ||
        companyWebsite === "" ||
        founderContact === "",
      investment_type === "",
      capital === "" || isFundingreceived === "")
    ) {
      toast.error("You Have to fill all fields");
    } else if (urlPattern.test(companyWebsite) === false) {
      toast.error("Company Website URL is not correct");
      setWebsiteError(true);
    } else if (founderContact?.length > 10 || founderContact?.length < 10) {
      setContactError(true);
      toast.error("Contact Number should be 10 digit");
    } else {
      const formData = {
        startupId: pathID,
        categories_id: categoryID,
        name: name,
        company_name: companyName,
        company_website: companyWebsite,
        founder_contact: founderContact,
        founder_email: founderEmail,
        investment_type: investment_type,
        capital: capital,
        funding_rec_p_year: isFundingreceived,
        funding_rec_p_amount: funding_rec_p_amount,
        founded_year: founded_year,
        pitchdeck: pitchEdit?.length === 0 ? pitch : pitchEdit,
        valuationReport: valuationEdit?.length === 0 ? valuation : valuationEdit,
      };
      // console.log(formData, "formDataformDataformData");
      EditStartupsApi(formData, setUpdatePage);
    }
  };

  return (
    <div className="container">
      <ToastContainer position="top-center" />
      <h4 className="text-start mt-4 fw-bold">Basic Details</h4>
      <div className="row">
        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label htmlFor="fname">Company Name</label>
            <input
              required
              type="text"
              id="fname"
              name="fname"
              placeholder="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label  style={{color:websiteError? 'red':'#000'}} htmlFor="fname">Company Website</label>
            <input
              required
              style={{color:websiteError? 'red':'#000', border: contactError? '1px solid red':' '}}
              type="text"
              id="fname"
              name="fname"
              placeholder="Company Website"
              value={companyWebsite}
              onChange={(e) => setCompanyWebsite(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label htmlFor="fname">Founder Name</label>
            <input
              required
              type="text"
              id="fname"
              name="fname"
              placeholder="Founder Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label htmlFor="fname">Founder Email</label>
            <input
            disabled
              required
              type="email"
              id="fname"
              name="fname"
              placeholder="Founder Email"
              value={founderEmail}
              onChange={(e) => setfounderEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label style={{color:contactError? 'red':'#000'}} htmlFor="fname">Contact Number</label>
            <input
              style={{color:contactError? 'red':'#000', border: contactError? '1px solid red':' '}}
              type="number"
              id="fname"
              name="fname"
              placeholder="Contact Number"
              value={founderContact}
              onChange={(e) => setFounderContact(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label htmlFor="fname">Year Founded</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={founded_year}
              onChange={(e) => setfounded_year(e.target.value)}
            >
              <option selected>2012</option>
              <option value={1990}>1990</option>
              <option value={1991}>1991</option>
              <option value={1992}>1992</option>
              <option value={1993}>1993</option>
              <option value={1994}>1994</option>
              <option value={1995}>1995</option>
              <option value={1996}>1996</option>
              <option value={1997}>1997</option>
              <option value={1998}>1998</option>
              <option value={1999}>1999</option>
              <option value={2000}>2000</option>
              <option value={2001}>2001</option>
              <option value={2001}>2002</option>
              <option value={2002}>2002</option>
              <option value={2003}>2003</option>
              <option value={2004}>2004</option>
              <option value={2005}>2005</option>
              <option value={2006}>2006</option>
              <option value={2007}>2007</option>
              <option value={2008}>2008</option>
              <option value={2009}>2009</option>
              <option value={2010}>2010</option>

              <option value={2011}>2011</option>
              <option value={2012}>2012</option>
              <option value={2013}>2013</option>
              <option value={2014}>2014</option>
              <option value={2015}>2015</option>
              <option value={2016}>2016</option>
              <option value={2017}>2017</option>
              <option value={2018}>2018</option>
              <option value={2019}>2019</option>
              <option value={2020}>2020</option>
              <option value={2021}>2021</option>
              <option value={2022}>2022</option>
              <option value={2023}>2023</option>
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label htmlFor="fname">Capital Required</label>
            <input
              required
              type="number"
              id="fname"
              name="fname"
              placeholder="Capital Required"
              value={capital}
              onChange={(e) => setCapital(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label htmlFor="fname">Sector</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {categories &&
                categories?.map((item, i) => {
                  return (
                    <option
                      onClick={() => setCategoryID(item.id)}
                      value={item.name}
                      key={i}
                    >
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label htmlFor="fname">Investment</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={investment_type}
              onChange={(e) => setinvestment_type(e.target.value)}
            >
              <option value="Equity">Equity</option>
              <option value="Debt">Debt</option>
              <option value="Both">Both</option>
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="edit-details-input-single mt-3">
            <label htmlFor="fname">Previous Funding Recieved</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={isFundingreceived}
              onChange={(e) => setIsFundingReceived(e.target.value)}
            >
              <option selected value="Yes">
                Yes
              </option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        {isFundingreceived === "Yes" ? (
          <div className="col-md-3">
            <div className="edit-details-input-single mt-3">
              <label htmlFor="fname">Previous Funding Recieved Amount</label>
              <input
                type="number"
                id="fname"
                name="fname"
                placeholder="Previous Funding Recieved Amount"
                value={funding_rec_p_amount}
                onChange={(e) => setfunding_rec_p_amount(e.target.value)}
              />
            </div>
          </div>
        ) : null}

        <div className="col-md-3"></div>

        {investment_type === "Equity" || investment_type === "Both" ? (
            <>
          <div className="col-md-12">
            <div className="row edit-basic-detail-main py-3">
              <div className="col-md-2">
                <h5>Pitch Deck</h5>
              </div>
              <div className="col-md-6">
                {pitch === null ? (
                  <div className="upload_document_field">
                    <label htmlFor="pitch">
                      {pitchLoader ? (
                        <CircularProgress size={18} color="success" />
                      ) : pitchEdit.length === 0 ? (
                        <FileUploadIcon />
                      ) : (
                        <BsCheck2Circle style={{ color: "green" }} />
                      )}
                      <input
                        name="files[]"
                        multiple
                        id="pitch"
                        type="file"
                        placeholder="something"
                        className="form-control "
                        accept=".pdf"
                        onChange={(event) => {
                          if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            EditSingleFileUploadAddStartup(
                              event.target.files,
                              "pithdeck",
                              event.target.files.length,
                              setPitchLoader,
                              (data) => {
                                setPitchEdit(data);
                              }
                            );
                          }
                        }}
                      />
                    </label>
                    <h6>Only:.pdf</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center ">
                    {pitch?.map((item, i) => {
                      return (
                        <div className="mx-4" key={i}>
                          <a href={pitch[i]} download>
                            View File{i + 1}{" "}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                {pitch ===null ? (
                  <button className="pending_button">Pending</button>
                ) : (
                  <div>
                    <button
                      onClick={() => {
                        setPitch([]);
                      }}
                      className="edit-upload-button"
                    >
                      Edit
                    </button>
                    <button>Uploaded</button>
                  </div>
                )}
              </div>
            </div>
          </div>

           <div className="col-md-12">
            <div className="row edit-basic-detail-main py-3">
              <div className="col-md-2">
                <h5>Valuation Report</h5>
              </div>
              <div className="col-md-6">
                {valuation === null ? (
                  <div className="upload_document_field">
                    <label htmlFor="valuation">
                      {valuationLoader ? (
                        <CircularProgress size={18} color="success" />
                      ) : valuationEdit.length === 0 ? (
                        <FileUploadIcon />
                      ) : (
                        <BsCheck2Circle style={{ color: "green" }} />
                      )}
                      <input
                        name="files[]"
                        multiple
                        id="valuation"
                        type="file"
                        placeholder="something"
                        className="form-control "
                        accept=".pdf"
                        onChange={(event) => {
                          if (event.target.files.length > 4) {
                            toast.error("Files Should Not be Above 4");
                          } else {
                            EditSingleFileUploadAddStartup(
                              event.target.files,
                              "pithdeck",
                              event.target.files.length,
                              setValuationLoader,
                              (data) => {
                                setValuationEdit(data);
                              }
                            );
                          }
                        }}
                      />
                    </label>
                    <h6>Only:.pdf</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center ">
                    {valuation?.map((item, i) => {
                      return (
                        <div className="mx-4" key={i}>
                          <a href={valuation[i]} download>
                            View File{i + 1}{" "}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                {valuation === null ? (
                  <button className="pending_button">Pending</button>
                ) : (
                  <div>
                    <button
                      onClick={() => {
                        setValuation([]);
                      }}
                      className="edit-upload-button"
                    >
                      Edit
                    </button>
                    <button>Uploaded</button>
                  </div>
                )}
              </div>
            </div>
          </div>
          </>
        ) : null}
      </div>
      <div className="edit-info-btn">
        <button onClick={onUpdateBasicDetails}>UPDATE</button>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    getIsLodaerRedux: state.isLoader.isLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoaderRedux: (parameter) => {
      dispatch(setLoader(parameter));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBasicDetails);


