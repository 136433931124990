import React,{useEffect} from "react";
import TopBar from "./TopBar";
import NavBar from "./NavBar";
import Banner from "./Banner";
import Partner from "./Partner";
import Sponsors from "./Sponsors";
import MiddleSection from "./MiddleSection";
import Funding from "./Funding";
import Entrepreneur from "./Entrepreneur";
import BusinessModel from "./BusinessModel";
import BannerBottom from "./BannerBottom";
import Founder from "./Founder";
import Footer from "./Footer";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../css/home.css";

var token = localStorage.getItem("VinestToken");
function Home({getuserDataRedux}) {
  const navigation = useNavigate();

  useEffect(()=>{

  },[])
  return (
    <div>
      <TopBar />
          <div className="banner-main">
            <NavBar />
            <Banner />
            <BannerBottom />
            <Partner />
          </div>

      {/* <Partner /> */}
      <Funding />
      <MiddleSection />
      <Sponsors />
      <Entrepreneur />
      <BusinessModel />
      <Founder />
      <Footer />
    </div>
  );
}


const mapStateToProps = state => {
  return {
    getuserDataRedux:state.user.data,
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

