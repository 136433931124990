import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/sponsor.css";

function Sponsors() {
  const navigation =useNavigate()
  const [anim,setAnim] = useState('')
  const [anim2,setAnim2] = useState('')
  const [anim3,setAnim3] = useState('')
 

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    if(scrollPosition>550 ){
      setAnim('w3-container w3-center w3-animate-left')
      setAnim2('w3-container w3-center w3-animate-top')
      setAnim3('w3-container w3-center w3-animate-right')
    } else if(scrollPosition<550){
      setAnim('')
      setAnim2('')
      setAnim3('')
    }
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="container my-1 my-sm-5 py-2 py-sm-2">
        <div className="column">
          <div className="sponsors-heading">
            <h1>
               <span> A Platform Built For The Success of Startups And Investors</span>
            </h1>
            <h4>V!nest connects the right investors with the right sponsors.</h4>
          </div>
          <div className="container my-5">
            <div className="row">
              <div className="col-md-4">
                <div className={`sponsors-card ${anim}`}>
                  <img src="/Icons/Rocket-1.png" alt="image" />
                  <h2>Start-Ups</h2>
                  <p>
                  We provide capital across all industries and assist start-ups with financing their marketing, expansion, development, and initial costs.</p>
                  <button onClick={()=>navigation('/startup-login')} >GET FUNDED</button>
                </div>
              </div>

              <div className="col-md-4">
                <div  className={`sponsors-card ${anim2}`}>
                  <img src="/Icons/coininhand.png" alt="image" />
                  <h2>Investors</h2>
                  <p>
                  A golden opportunity to multiply your wealth, diversify your portfolio, and increase the probability of investment returns.
                  </p>
                  <button onClick={()=>navigation('/investor-login')} >START INVESTING</button>
                </div>
              </div>
              <div className="col-md-4">
                <div className={`sponsors-card ${anim3}`}>
                  <img src="/Icons/Meeting.png" alt="image" />
                  <h2>Partners</h2>
                  <p>
                  Earn substantial incentives for each referral that brings high-quality deals. Lucrative Incentives can motivate you to grab more deals.
                  </p>
                  <button onClick={()=>navigation('/partner-login')} >PARTNER NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sponsors;
