import React, { useEffect, useRef, useState } from "react";
import "../css/bannerbottom.css";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function DashboardBanner({ sliderImage }) {
  // console.log(sliderImage, "sliderImage");

  return (
    <>
      <div className="table-banner-bottom">
        <div className="container-fluid p-0 m-0">
          <div className="row">
            <OwlCarousel
              className="owl-theme"
              autoplayTimeout={2000}
              loop
              margin={10}
              nav
              autoplay={true}
              items={1}
              dots={false}
            >
              {sliderImage && sliderImage?.map((item, i) => {
                return (
                  <div key={i} className="item table-banner-bottom-main">
                    <img src={item.image} alt="image" />
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    sliderImage: state.slider.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBanner);
