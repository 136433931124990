import React, { useEffect, useRef } from "react";
import "../css/bannerbottom.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function DashboardCategorySlider({tableData}) {
  return (
    <>
      <div className="table-banner-bottom ">
        <div className="container-fluid p-0 m-0">
          <div className="row">
            {
              tableData?.length < 4 ?
              <>
               { tableData && tableData?.map((item,i)=>{
                  return(
                  <div key={i} className="col-4  item dashboard-category-slider_2">
                    <h5>{item?.company_name}</h5>
                    <div className="d-flex justify-content-between">
                      <span style={{fontSize:'12px'}} className="dashboard-category-slider-price" >Funding : {item?.capital}$</span>
                      <h6>{item?.investment_type}</h6>
                    </div>
                  </div>
                  )
                })}
                </>
                :
                <OwlCarousel
              className="owl-theme"
              autoplayTimeout={5000}
              loop
              margin={10}
              nav={true}
              autoplay={true}
              items={4}
              dots={false}
            >
             {
              tableData && tableData?.map((item,i)=>{
                return(
                  <div key={i} className="item dashboard-category-slider">
                  <h5>{item?.company_name}</h5>
                  <div className="d-flex justify-content-between">
                    <span style={{fontSize:'12px'}} className="dashboard-category-slider-price" >Funding : {item?.capital}$</span>
                    <h6>{item?.investment_type}</h6>
                  </div>
                </div>
                )
              })
             }
            </OwlCarousel>
               
            }
          
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardCategorySlider;
