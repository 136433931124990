import React from "react";
import { CiUser } from "react-icons/ci";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import { setUpdateScreen } from "../Redux";
import "../cssdashboard/index.css";

function DasboardNav({getUpdateScreenRedux,setUpdateScreenRedux}) {
  const navigation = useNavigate();

  return (
    <div className="nav-main">
      <div className="container-fluid">
        <div className="column">
          <div className="col-md-12">
            <div className="nav">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="logo">
                      <img
                        onClick={() => navigation("/")}
                        src="/img/vinest.png"
                        alt="Image"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="nav-list">
                      <ul>
                        {window.location.pathname === "/profile" ? null : (
                          <li onClick={() => navigation("/profile")}>
                            <CiUser />
                            Profile
                          </li>
                        )}
                        <li className="logout" onClick={()=>{
                          Swal.fire({
                            title: 'Are you sure?',
                            text: "Do You Want To Logout!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#343C5C',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, logout!'
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setUpdateScreenRedux(!getUpdateScreenRedux)
                              localStorage.setItem("VinestToken", null);
                              localStorage.removeItem("vinestStartupId");
                               navigation("/")
                               window.location.reload()
                            }
                          })
                            
                        }}>
                          Log Out <AiOutlineLogout />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="container">
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getUpdateScreenRedux:state.update.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateScreenRedux: (parameter) => {
      dispatch(setUpdateScreen(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DasboardNav);

