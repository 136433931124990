import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FilledProfileDetails } from "../Apis";
import { connect } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { setUpdateScreen } from "../Redux";
import { ToastContainer, toast } from "react-toastify";

import "../css/modalcss/changepassword.css";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#FE5902",
      contrastText: "#fff",
    },
  },
});


var vinestInfoModal = localStorage.getItem("vinestInfoModal");

function FilledInfoModal({ openModal, setOpenModal, getuserDataRedux , getUpdateScreenRedux, setUpdateScreenRedux}) {
  const handleClose = () => {
    setOpenModal(false);
    localStorage.setItem("vinestInfoModal", false);
    
  }
  const [userPhone, setUserPhone] = useState(
    getuserDataRedux?.data?.user?.phone
  );
  const [userCity, setUserCity] = useState(getuserDataRedux?.data?.user?.city);
  const [userProfession, setUserProfession] = useState(
    getuserDataRedux?.data?.user?.profession
  );

  const [editInfo, setEditInfo] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const updateProfileHandle = () => {
    if(userPhone.length>10){
   alert('Phone Number Should Not Be Greater 10')
    } else{
      FilledProfileDetails(
        userPhone,
        userCity,
        userProfession,
        setIsLoader,
        handleClose
      );
      setUpdateScreenRedux(!getUpdateScreenRedux)
    }
   
  };

  useEffect(() => {
    setUserPhone(getuserDataRedux?.data?.user?.phone);
    setUserCity(getuserDataRedux?.data?.user?.city);
    setUserProfession(getuserDataRedux?.data?.user?.profession);

  }, []);

  useEffect(()=>{
    if(vinestInfoModal==='false'){
      setOpenModal(false)
    }
    if(vinestInfoModal==='true'){
      setOpenModal(true)
    }

  },[vinestInfoModal])

  return (
    <div>
       <ToastContainer
        position="top-center"
      />
      <Modal
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        show={openModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Some Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="my-3">
            <input
              value={userProfession}
              onChange={(e) => setUserProfession(e.target.value)}
              type="text"
              placeholder="Your Profession"
              className="form-control"
            />
          </div>

          <div className="my-3">
            <input
              value={userCity}
              onChange={(e) => setUserCity(e.target.value)}
              type="text"
              placeholder="Your City"
              className="form-control"
            />
          </div>

          <div className="my-3">
            <input
              value={userPhone}
              onChange={(e) => setUserPhone(e.target.value)}
              type="number"
              placeholder="Your Mobile Number"
              className="form-control"
            />
          </div>
          <div className="my-3">
            {isLoader ? (
              <button className="change-password-button-loader">
                <CircularProgress color="info" size={20} />
              </button>
            ) : (
              <button
                onClick={updateProfileHandle}
                className="change-password-button"
              >
                Save Details
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getuserDataRedux: state.user.data,
    getUpdateScreenRedux:state.update.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateScreenRedux: (parameter) => {
      dispatch(setUpdateScreen(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilledInfoModal);
