import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DashboardNav from "../dashboards/DasboardNav";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { singleFileUpload , fetchSingleStartup, updateStartupDocument} from "../Apis";
import { setSingleStartupId } from "../Redux";
import {BsCheck2Circle} from 'react-icons/bs'
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { setLoader } from "../Redux";
import "../css/startuppage.css";

function UploadDocumentStartupPage2({getCategoryIdRedux,getSingleStartupIdRedux,setSingleStartupIdRedux,getIsLodaerRedux,setIsLoaderRedux}) {
  const navigation = useNavigate();
  const [msi, setMsi] = useState('')
  const [provisions, setProvisions] = useState('')
  const [auditedFinancial, setAuditedFinancial] = useState('')
  const [salesRegister, setSalesRegister] = useState('')
  const [bankStatements, setBankStatements] = useState('')
  const [gst,setGst] = useState('')
  const [debt_profile, setdebt_profile] = useState([])
  const [pan_number, setpan_number] = useState('')
  const [coi, setcoi] = useState('')
  const [din,setdin] = useState('')
  const [board_resolutions, setboard_resolutions] = useState('')

  useEffect(() => {
    fetchSingleStartup(getSingleStartupIdRedux,(data) => {
      // console.log(data, "startup single data fetch");
      setSingleStartupIdRedux(data?.id)
    });
  }, []);

const handleUploadDocuments=(e)=>{
  e.preventDefault()
  let formData ={
    "id": getSingleStartupIdRedux,
    "mis":  msi,
    "provisions": provisions,
    "audited_financial": auditedFinancial,
    "sales_register": salesRegister,
    "bank_statements": bankStatements,
    "gst": gst,
    "debt_profile": debt_profile,
    "pan_number":pan_number,
    "coi": coi, 
    "din":din,
    "board_resolutions": board_resolutions,  
    
  }
  updateStartupDocument(formData, navigation)
}

  return (
    <div>
      <ToastContainer position="top-center" />
       {getIsLodaerRedux ? (
        <div className="loader-spinner-main">
          <CircularProgress className="loader-spinner" color="success" />
        </div>
      ) : null}
      <DashboardNav />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="table-dashboard mt-5">
              <div className="d-flex justify-content-start align-items-center ">
                <button onClick={() => navigation("/startup-upload-documents-2")} className="upload-document-btn mx-2">
                  Upload Documents
                </button>
              </div>

              <div className="table-uploade py-5">
                <Table responsive="lg">
                  <thead className="table-main-upload-2">
                    <tr>
                      <th>Documents</th>
                      <th>Debt Profile </th>
                      <th>MIS </th>
                      <th>Provisions</th>
                      <th>Audited Financials</th>
                      <th>Sales Register </th>
                      <th>Bank Statements</th>
                      <th>GST</th>
                    </tr>
                  </thead>
                  <tbody className="table-main-upload-2-body">
                    <tr>
                      <td>Uploaded Status</td>
                      <td className='upload_document_field'>
                        <label htmlFor='debt' >
                          {
                            debt_profile?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                          name="files[]"
                          multiple
                          id='debt'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".csv,.xlsx"
                          onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'debt_profile' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setdebt_profile(data)
                            })
                          }
                          }}
                        />
                        </label>
                        <h6>Only: .csv,.xlsx</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='MIS' >
                         {
                            msi?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='MIS'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".csv,.xlsx"
                          onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'msi' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setMsi(data)
                            })
                          }
                          }}
                        />
                        </label>
                        <h6>Only: .csv,.xlsx</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='Provisions' >
                        {
                            provisions?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='Provisions'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".pdf"
                          onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'provisions' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setProvisions(data)
                            })
                          }
                          }}
                        />
                        </label>
                        <h6>Only:.pdf</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='Audited' >
                        {
                            auditedFinancial?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='Audited'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".pdf"
                          onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'audited_financial' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setAuditedFinancial(data)
                            })
                          }
                          }}
                        />
                        </label>
                        <h6>Only:.pdf</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='Sales' >
                        {
                            salesRegister?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='Sales'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".xlsx,.csv,.xls,.jpeg,.png,.jpg,.gif,.svg,.pdf"
                          onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'sales_register' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setSalesRegister(data)
                            })
                          }
                          }}
                        />
                        </label>
                        <h6>Only:.xlsx,.csv,.png,.jpg,.svg,.pdf</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='Bank' >
                        {
                            bankStatements?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='Bank'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".pdf,.zip"
                           onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'bank' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setBankStatements(data)
                            })
                          }
                          }}
                           
                        />
                        </label>
                        <h6>Only:.pdf,.zip</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='GST' >
                        {
                            gst?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='GST'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".pdf,.zip"
                           onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'gst' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setGst(data)
                            })
                          }
                          }}
                        />
                        </label>
                        <h6>Only:.pdf,.zip</h6>
                      </td>
                    </tr>
                 
                  </tbody>
                </Table>

                <Table responsive="lg mt-5">
                  <thead className="table-main-upload-2">
                    <tr>
                      <th>Documents</th>
                      <th>Pan Number</th>
                      <th>COI</th>
                      <th>DIN</th>
                      <th>Board Resolutions</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-main-upload-2-body">
                    <tr>
                      <td>Uploaded Status</td>
                      <td className='upload_document_field'>
                        <label htmlFor='Pan' >
                        {
                            pan_number?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='Pan'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".pdf,.jpeg,.png,.jpg"
                          onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'pan_number' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setpan_number(data)
                            })
                          }
                          }}
                        />
                        </label>
                        <h6>Only:.pdf,.jpeg,.png,.jpg</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='COI' >
                        {
                            coi?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='COI'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".pdf,.jpeg,.png,.jpg"
                          onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'coi' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setcoi(data)
                            })
                          }
                          }}
                        />
                        </label>
                        <h6>Only:.pdf,.jpeg,.png,.jpg</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='DIN' >
                        {
                            din?.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='DIN'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".pdf, jpeg, png, jpg."
                          onChange={(event)=>{
                            if(event.target.files.length>4){
                              toast.error('Files Should Not be Above 4')
                            }else{
                              singleFileUpload(getSingleStartupIdRedux,event.target.files, 'din' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                                setdin(data)
                              })
                            }
                           
                          }}
                        />
                        </label>
                        <h6>Only:.pdf, jpeg, png, jpg.</h6>
                      </td>
                      <td className='upload_document_field'>
                        <label htmlFor='Board' >
                        {
                            board_resolutions.length===0?
                            <FileUploadIcon/> 
                            :
                            <BsCheck2Circle style={{color:'green'}}/>
                          }
                        <input
                        name="files[]"
                        multiple
                          id='Board'
                          type="file"
                          placeholder="something"
                          className="form-control "
                          accept=".pdf,.jpeg,.png,.jpg"
                         onChange={(event)=>{
                          if(event.target.files.length>4){
                            toast.error('Files Should Not be Above 4')
                          } else{
                            singleFileUpload(getSingleStartupIdRedux,event.target.files, 'board_resolutions' ,event.target.files.length , setIsLoaderRedux, (data)=>{
                              setboard_resolutions(data)
                            })
                          }
                        }}
                        />
                        </label>
                        <h6>Only:.pdf,.jpeg,.png,.jpg</h6>
                      </td>
                      <td className='upload_document_field_blank'>
                        {/* <span>for hiding part</span> */}
                      </td>
                      <td className='upload_document_field_blank'>
                        {/* <span>for hiding part</span> */}
                      </td>
                      <td className='upload_document_field_blank'>
                        {/* <span>for hiding part</span> */}
                      </td>
                    </tr>
                 
                  </tbody>
                </Table>

               
              </div>
              <div className="next-button-uploaded">
                <button onClick={(e)=>handleUploadDocuments(e)} >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    getCategoryIdRedux:state.categoryId.id,
    getSingleStartupIdRedux: state.singleStartupId.id,
    getIsLodaerRedux: state.isLoader.isLoader,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setSingleStartupIdRedux:parameter=>{ dispatch(setSingleStartupId(parameter))},
    setIsLoaderRedux: (parameter) => {
      dispatch(setLoader(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentStartupPage2);


