import { baseUrl } from "./baseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";

var token = localStorage.getItem("VinestToken");
var startupId = localStorage.getItem("vinestStartupId");

export const fetchUserDetails = (callback) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-user`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
  };
  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data), 'fetch user api');
      return callback(response.data);
    })
    .catch((error) => {
      // return callback(null);
    });
};

export const getLoginApi = (email, password, navigation, role) => {
  let data = JSON.stringify({
    email: email,
    password: password,
    role:role
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(response.data,'login starup');
      let userData = response?.data?.data?.user;
      if(userData?.city===null || userData?.profession===null || userData?.phone===null){
        localStorage.setItem("vinestInfoModal", true);
      }

      if(userData?.city!==null && userData?.profession!==null && userData?.phone!==null){
        localStorage.setItem("vinestInfoModal", false);
      }
      localStorage.setItem("vinestStartupId", JSON.stringify(userData?.startupId));
      localStorage.setItem(
        "VinestToken",
        JSON.stringify(response.data?.data?.token)
      );
      if (response?.data.status === "success") {
          
       Swal.fire({
          title: "Success",
          text: response.data.message,
          icon: "success",
          confirmButtonColor: "#343C5C",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          localStorage.setItem(
            "VinestToken",
            JSON.stringify(response.data?.data?.token)
          );
          navigation("/dashboard");
          window.location.reload();
        });

      } else {
        // console.log(response.data.message,'response.data.message login')
        toast.error(response.data.message, {
          theme: "colored",
        });
      }
    })
    .catch((error) => {
      // console.log(error,'login error')
      toast.error("Something Went Wrong", {
        theme: "colored",
      });
    });
};

export const getLoginApiForStartup = (email, password, navigation, role) => {
  let data = JSON.stringify({
    email: email,
    password: password,
    role:role
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(response.data,'login starup');
      let userData = response?.data?.data?.user;
      localStorage.setItem("vinestStartupId", JSON.stringify(userData?.startupId));
      localStorage.setItem(
        "VinestToken",
        JSON.stringify(response.data?.data?.token)
      );
      if (response?.data.status === "success") {

          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonColor: "#343C5C",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            localStorage.setItem(
              "VinestToken",
              JSON.stringify(response.data?.data?.token)
            );

            let data = JSON.stringify({
              id: userData?.startupId,
            });
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${baseUrl}fetch-single-startup`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${response.data?.data?.token?.replaceAll('"', '')}`,
              },
              data: data,
            };
          
            axios
              .request(config)
              .then((response) => {
                // console.log(response.data,'/////////////////////');
                if(response?.data?.data?.startup?.company_name===null || response?.data?.data?.startup?.categoryName==null || response?.data?.data?.startup?.company_website===null){
                  navigation("/add-startup");
                  window.location.reload();
                } else{
                  navigation("/profile");
                  window.location.reload();
                }
              })
              .catch((error) => {
              });

          });

      } else {
        // console.log(response.data.message,'response.data.message login')
        toast.error(response.data.message, {
          theme: "colored",
        });
      }
    })
    .catch((error) => {
      // console.log(error,'login error')
      toast.error("Something Went Wrong", {
        theme: "colored",
      });
    });
};

export const registrationApi = (name, email, password, role,referralCode, parentId, callback) => {
  let data = JSON.stringify({
    name: name,
    email: email,
    password: password,
    role: role,
    parent_id: parentId,
    use_referral:referralCode
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      toast.error("Something Went Wrong", {
        theme: "colored",
      });
    });
};

export const forgotPasswordApi = (email, callback) => {
  let data = JSON.stringify({
    username: email,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}forgot-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      toast("Something Went Wrong", {
        theme: "colored",
      });
    });
};

export const fetchSliders = (callback) => {
  let data = "";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-sliders`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data), 'sliders');
      callback(response.data);
    })
    .catch((error) => {
      // console.log(error,'slider error');
    });
};

export const addStartups = (formData, navigation, callback) => {
  let data = JSON.stringify(formData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-startup`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(response.data);
      if (response.data.status === "failed") {
        toast.error(response.data.message);
      } else {
        toast(response.data.message);
        navigation("/startup-upload-documents");
        callback(response.data);
      }
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const addStartupFinancial = (formData, navigation) => {
  let data = JSON.stringify(formData);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-startup-financial`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data),'fincancinal');

      if (response.data.status === "failed") {
        toast.error(response.data.message);
      } else {
        toast(response.data.message);
        navigation("/startup-upload-documents-2");
      }
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const addStartupFinancialUpdate = (formData, navigation,setPageLoader) => {
  setPageLoader(true)
  let data = JSON.stringify(formData);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-startup-financial`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data),'fincancinal');

      if (response.data.status === "failed") {
        toast.error(response.data.message);
        setPageLoader(false)
      } else {
        toast(response.data.message);
        setPageLoader(false)
      }
    })
    .catch((error) => {
      // console.log(error);
      setPageLoader(false)
    });
};

export const fetchCategories = (callback) => {
  let data = "";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-categories`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data), 'category dtaaa');
      callback(response.data);
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const singleFileUpload = (id, file, type,fileLength, setIsLoaderRedux,callback) => {
  setIsLoaderRedux(true)
  let bodyFormData = new FormData();
  bodyFormData.append("id", id ? id : startupId);
  bodyFormData.append("type", type);

  for (let i = 0; i < fileLength; i++) {
  bodyFormData.append("file[]", file[i]);
  }


  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}file-uploads`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: bodyFormData,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data),'66666666666666666666');
      setIsLoaderRedux(false)
      let resData= response.data.files
      let sendResData=[]
      for (let i = 0; i < resData.length; i++) {
        sendResData.push(resData[i]?.sendPath) ;
      }
      callback(sendResData);
    })
    .catch((error) => {
      // console.log(error);
      setIsLoaderRedux(false)
      toast.error('Something Went Wrong With Uploading')
    });
};

export const singleFileUploadAddStartup = ( file, type, lengthData , callback) => {

let bodyFormData = new FormData();
bodyFormData.append("type", type);

for (let i = 0; i < lengthData; i++) {
  bodyFormData.append("file[]", file[i]);
}

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}file-uploaded`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: bodyFormData,
  };

  axios
    .request(config)
    .then((response) => {
      let resData= response.data.files
      let sendResData=[]
      for (let i = 0; i < resData.length; i++) {
        sendResData.push(resData[i]?.sendPath) ;
      }
      callback(sendResData);
    })
    .catch((error) => {
      // console.log(error);
    });

 
};

export const fetchSingleStartup = (getSingleStartupIdRedux, callback) => {
  let data = JSON.stringify({
    id: getSingleStartupIdRedux ? getSingleStartupIdRedux : startupId,
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-single-startup`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      callback(response.data.data.startup);
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const fetchSingleStartupForEdit = (id, setIsLoaderRedux, callback) => {
  setIsLoaderRedux(true)
  let data = JSON.stringify({
    id: id,
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-single-startup`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      setIsLoaderRedux(false)
      // console.log(JSON.stringify(response.data));
      callback(response.data.data.startup);
    })
    .catch((error) => {
      // console.log(error);
      setIsLoaderRedux(false)
    });
};

export const fetchStartup = (setIsLoaderRedux, selectType, callback) => {
  setIsLoaderRedux(true);
  let data = JSON.stringify({
    page: 1,
    limit: 10000,
    type: selectType,
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-startups`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(response.data,'---------cghedf');
      callback(response.data);
      setIsLoaderRedux(false);
    })
    .catch((error) => {
      // console.log(error);
      setIsLoaderRedux(false);
    });
};

export const updateStartupDocument = (formData, navigation) => {
  let data = JSON.stringify(formData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-startup-documents`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(response.data, 'update formdata');
      navigation("/dashboard");
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const profileImageUpdate = (file, setIsLoaderRedux) => {
  setIsLoaderRedux(true);
  let bodyFormData = new FormData();
  bodyFormData.append("profile", file);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}profile-update`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: bodyFormData,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      if (response.data.status === "failed") {
        toast.error(response.data.message);
      } else {
        toast(response.data.message);
      }
      setIsLoaderRedux(false);
    })
    .catch((error) => {
      // console.log(error);
      setIsLoaderRedux(false);
    });
};

export const updateProfileDetails=(name , phone,city,profession, setIsLoaderRedux,setEditInfo)=>{
  setIsLoaderRedux(true)
  let data = JSON.stringify({
    "name": name,
    "phone": phone,
    "city":city,
    "profession":profession
  });
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}info-update`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token?.replaceAll('"', '')}` 
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    // console.log(JSON.stringify(response.data));
    setIsLoaderRedux(false)
    if (response.data.status === "failed") {
      toast.error(response.data.message);
      setEditInfo(true)
    } else {
      toast(response.data.message);
      setEditInfo(false)
    }
  })
  .catch((error) => {
     setIsLoaderRedux(false)
     setEditInfo(true)
  });
}

export const FilledProfileDetails=(phone,city,profession,setIsLoaderRedux,handleClose)=>{
  setIsLoaderRedux(true)
  let data = JSON.stringify({
    "phone": phone,
    "city":city,
    "profession":profession
  });
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}info-update-other`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token?.replaceAll('"', '')}` 
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    // console.log(JSON.stringify(response.data));
    setIsLoaderRedux(false)
    if (response.data.status === "failed") {
      toast.error(response.data.message);
    
    } else {
      handleClose()
      // toast(response.data.message);
      Swal.fire({
        title: "Success",
        text: response.data.message,
        icon: "success",
        confirmButtonColor: "#343C5C",
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(()=>{
        window.location.reload()
      }).catch(()=>{
        window.location.reload()
      })
     
    }
  })
  .catch((error) => {
    // console.log(error);   
     setIsLoaderRedux(false)
  });
}

export const EditAndupdateStartupDocument = (formData, navigation) => {
  let data = JSON.stringify(formData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-startup-documents`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(response.data, 'update formdata');
      toast(response.data.message)
    })
    .catch((error) => {
      // console.log(error);
      toast.error('Something Went Wrong With Uploading')
    });
};



export const EditaddStartupFinancial = (formData, navigation) => {
  let data = JSON.stringify(formData);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-startup-financial`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', '')}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data),'fincancinal');

      if (response.data.status === "failed") {
        toast.error(response.data.message);
      } else {
        toast(response.data.message);
      }
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const EditStartupsApi = (formData,setIsLoaderRedux) => {
  setIsLoaderRedux(true)
    let data = JSON.stringify(formData);
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}update-startup-info`,
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token?.replaceAll('"', '')}`, },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      setIsLoaderRedux(false)
      if (response.data.status === "failed") {
        toast.error(response.data.message);
      } else {
        toast(response.data.message);
      }
    })
    .catch((error) => {
      toast.error('Something Went Wrong');
      setIsLoaderRedux(false)
    });
};


export const EditSingleFileUploadAddStartup = ( file, type, lengthData ,setLoader, callback) => {
  setLoader(true)
  let bodyFormData = new FormData();
  bodyFormData.append("type", type);
  
  for (let i = 0; i < lengthData; i++) {
    bodyFormData.append("file[]", file[i]);
  }
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}file-uploaded`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token?.replaceAll('"', '')}`,
      },
      data: bodyFormData,
    };
  
    axios
      .request(config)
      .then((response) => {
        setLoader(false)
        let resData= response.data.files
        let sendResData=[]
        for (let i = 0; i < resData.length; i++) {
          sendResData.push(resData[i]?.sendPath) ;
        }
        callback(sendResData);
      })
      .catch((error) => {
        setLoader(false)
      });
  
   
  };
  

  export const AddContactFormApi = (formData, setEmail, setPhone, setName, setMessage) => {
    let data = JSON.stringify(formData);
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}contact`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        console.log(response.data,'cont')
        if (response.data.status === "failed") {
        
          Swal.fire({
            title: "Failed",
            text: response.data.message,
            icon: "danger",
            confirmButtonColor: "#343C5C",
          })
        } else {
          setEmail('')
           setPhone('')
           setName('')
           setMessage('')
          Swal.fire({
            title: response.data.heading,
            text:  response.data.message,
            icon: "success",
            confirmButtonColor: "#343C5C",
          })
        }
      })
      .catch((error) => {
      
        Swal.fire({
          title: "Failed",
          text:   'Something Went Wrong',
          icon: "danger",
          confirmButtonColor: "#343C5C",
        })
      });
  };

  export const QueryForInvestorApi = (formData,setUserEmail) => {
    let data = JSON.stringify(formData);
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}invertor-subscribe`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        if (response.data.status === "failed") {
          Swal.fire({
            title: "Failed",
            text:   'Something Went Wrong',
            icon: "danger",
            confirmButtonColor: "#343C5C",
          })
        } else {
          setUserEmail('')
          Swal.fire({
            title: response.data.heading,
            text:  response.data.message,
            icon: "success",
            confirmButtonColor: "#343C5C",
          })
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Failed",
          text:   'Something Went Wrong',
          icon: "danger",
          confirmButtonColor: "#343C5C",
        })
      });
  };

  export const RevenueFetchApi =(token, callback)=>{
    let data = '';

let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: `${baseUrl}fetch-revenue`,
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization':  `Bearer ${token?.replaceAll('"', '')}` },
  data : data
};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
  callback(response.data.data.revenue)
})
.catch((error) => {
  console.log(error);
});
  }

  export const SearchingApi=(formdata, callback)=>{
    let data = JSON.stringify(formdata);
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}startup-search`,
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization':  `Bearer ${token?.replaceAll('"', '')}` 
       },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      callback((response.data.data.startups))

    })
    .catch((error) => {
      console.log(error);
    });
  }