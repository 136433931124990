import React from "react";
import "../css/founder.css";

function Founder() {
  return (
    <div className="founder-main-head">
      <div className="container my-3 py-4">
      <div className="column">
        <div className="founder-heading">
          <h1>
            <span>Loved By Founders</span>
          </h1>
          <p>As they supercharge growth by unlocking their biggest asset</p>
        </div>

        <div className="container my-4">
          <div className="row">
            <div className="col-md-6">
              <div className="founder">
                <div className="founder-main">
                  <p className="mb-4" >
                    "Vinest provided us hassle free and fastest access to growth capital. It was a very smooth and convenient experience for us. “
                  </p>
                  <div className="founder-profile">
                    {/* <img src="/Icons/user1.png" alt="Image" /> */}
                    <h4>Plum </h4>
                    {/* <div className="founder-profile-text">
                      <h4>Blumatic </h4>
                      <p>Founder Expert & CEO</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="founder-2">
                <div className="founder-main">
                  <p>
                    "Loved the experience of working with Vinest. The prompt response and efficient process helped us save a lot of time as well as raise the suitable capital for the growth of our business.“
                  </p>
                  <div className="founder-profile">
                    {/* <img src="/Icons/user2.png" alt="Image" /> */}
                    <div className="founder-profile-text">
                      <h4>Civil Guruji </h4>
                      {/* <p>Founder Expert & CEO</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Founder;
