import React, { useState, useEffect } from "react";
import DasboardNav from "../dashboards/DasboardNav";
import Footer from "./Footer";
import { BsFillCameraFill } from "react-icons/bs";
import {AiTwotoneCopy} from 'react-icons/ai'
import ChangePasswordModal from "../modals/ChangePasswordModal";
import DeleteAccountModal from "../modals/DeleteAccountModal";
import BasicDetails from "../FormsDetails/BasicDetails";
import { MdOutlineArrowDropDown } from "react-icons/md";
import FinancialDetails from "../FormsDetails/FinancialDetails";
import { MdKeyboardArrowRight } from "react-icons/md";
import DocumentsDetails from "../FormsDetails/DocumentsDetails";
import { profileImageUpdate, updateProfileDetails, fetchSingleStartup, fetchUserDetails } from "../Apis";
import { connect } from "react-redux";
import { setLoader ,setUserData,setSingleStartupId} from "../Redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import "./../css/profile.css";

function Profile({ setIsLoaderRedux, getIsLodaerRedux, getuserDataRedux,getSingleStartupIdRedux,userDataRedux,setSingleStartupIdRedux }) {
  const navigation =useNavigate()
  let userDataDetails = getuserDataRedux?.data?.user;
  const [startupUserData, setStartupUserData] = useState([])
  const [userName,setUserName] = useState(userDataDetails?.name)
  const [userEmail,setUserEmail] = useState(userDataDetails?.email)
  const [userPhone,setUserPhone] = useState(userDataDetails?.phone)
  const [city, setCity] = useState(userDataDetails?.city)
  const [profession,setProfession] = useState(userDataDetails?.profession)

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [showBasicDetails, setShowBasicDetails] = useState(false);
  const [showFinancialDetails, setShowFinancialDetails] = useState(false);
  const [showDocumentDetails, setShowDocumentDetails] = useState(false);


  useEffect(()=>{
    setUserName(userDataDetails?.name)
    setUserEmail(userDataDetails?.email)
    setUserPhone(userDataDetails?.phone)
    setCity(userDataDetails?.city)
    setProfession(userDataDetails?.profession)
    fetchSingleStartup(getSingleStartupIdRedux,(data)=>{
       setStartupUserData(data)
    })
  },[userDataDetails,getIsLodaerRedux])

  useEffect(()=>{
    fetchUserDetails((data)=>{
      userDataRedux(data)
      setSingleStartupIdRedux(data.data?.user?.startupId)
    })
  },[getIsLodaerRedux])

  console.log(userDataDetails,'userDataDetailsuserDataDetails')

  return (
    <div>
      {getIsLodaerRedux ? (
        <div className="loader-spinner-main-profile">
          {/* <CircularProgress className="loader-spinner" color="success" /> */}
          <img src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt="loader-img" />
        </div>
      ) : null}

      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
      <ChangePasswordModal setOpenModal={setOpenModal} openModal={openModal} />
        <DeleteAccountModal
          setOpenDeleteModal={setOpenDeleteModal}
          openDeleteModal={openDeleteModal}
        />

      <div className="profile_navbar_main">
        <DasboardNav />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="profile">
            <div className="col-md-12">
              <div className="profile_header_main">
                <div className="container profile_main">
                  <div className="row">
                    <div className="col-md-2 p-0">
                      <div className="profile_image">
                        <img src={userDataDetails?.image===''?'/img/user-logo.png':userDataDetails?.image} alt="image" />
                        <label htmlFor="upload_img">
                          <BsFillCameraFill />
                          <input
                            id="upload_img"
                            type="file"
                            placeholder="something"
                            className="form-control "
                            onChange={(event) =>
                              profileImageUpdate(
                                event.target.files[0],
                                setIsLoaderRedux
                              )
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-7 p-0 d-flex justify-content-start align-items-center ">
                      <div className="profile_names">
                        <h3 className="text-start">{userDataDetails?.name}</h3>
                        <span className="text-start">
                          {userDataDetails?.email}
                        </span>
                      </div>
                    </div>
                      {
                        userDataDetails?.role==='1'
                        ?
                        null
                        :
                      <div className="col-md-3 p-0 d-flex justify-content-end align-items-center ">
                        <div className="goback-button-profile">
                          <button  onClick={()=>navigation('/dashboard')} >DASHBOARD</button>
                        </div>
                     </div>
                      }
                    

                    <div className="col-md-5 d-flex justify-content-between">
                    <div className="profiles_buttons">
                        <button onClick={() => setOpenDeleteModal(true)}>
                          Delete
                        </button>
                        <button
                          onClick={() => {
                            setEditInfo(true);  
                            if(editInfo){
                              updateProfileDetails(userName,userPhone, city, profession, setIsLoaderRedux,setEditInfo)
                            }
                          }}
                          className={editInfo ? "edit_profile_btn" : ""}
                        >
                          {editInfo ? "Save" : "Edit"}
                        </button>
                      </div>
                      <div onClick={() => {navigator.clipboard.writeText(`https://vinest.in/startup-register/${userDataDetails?.referral}`)}} className="copy_links">
                        <span>Copy Link</span>
                           <AiTwotoneCopy />  
                      </div>
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-4 mb-5">
          <div className="row">
            <div className="col-md-6 py-3">
              <div className="profile-imput-fileds">
                <label htmlFor="username">UserName</label>
                <input
                  disabled={editInfo ? false : true}
                  type="text"
                  placeholder="User Name"
                  className="form-control"
                  id="username"
                  value={userName}
                  onChange={(e)=>setUserName(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6 py-3">
              <div className="profile-imput-fileds">
                <label htmlFor="email">Email</label>
                <input
                  disabled
                  type="text"
                  placeholder="Email"
                  className="form-control"
                  id="email"
                  value={userEmail}
                  onChange={(e)=>setUserEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6 py-3">
              <div className="profile-imput-fileds">
                <label htmlFor="phone">Mobile Number</label>
                <input
                  disabled={editInfo ? false : true}
                  type="number"
                  placeholder="Mobile Number"
                  className="form-control"
                  id="phone"
                  value={userPhone}
                  onChange={(e)=>setUserPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6 py-3">
              <div className="profile-imput-fileds">
                <label htmlFor="profession">Profession</label>
                <input
                  disabled={editInfo ? false : true}
                  type="text"
                  placeholder="Your Profession"
                  className="form-control"
                  id="profession"
                  value={profession}
                  onChange={(e)=>setProfession(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6 py-3">
              <div className="profile-imput-fileds">
                <label htmlFor="city">City</label>
                <input
                  disabled={editInfo ? false : true}
                  type="text"
                  placeholder="Your City"
                  className="form-control"
                  id="city"
                  value={city}
                  onChange={(e)=>setCity(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6 py-3">
              <div className="profile-imput-fileds d-none">
                <label htmlFor="email">Email</label>
                <input
                  disabled={editInfo ? false : true}
                  type="text"
                  placeholder="Email"
                  className="form-control d-none"
                  id="email"
                  value="rahulroyr1997@gmail.com"
                />
              </div>
            </div>

            <div className="col-md-6 py-3">
              <div className="profile-button-change-password">
                <button onClick={() => setOpenModal(true)}>
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>

        {userDataDetails?.role === "4" ? null : (
          <div className="container">
            <div className="details-dropdown ">
              <div
                className="basic_details_heading"
                onClick={() => setShowBasicDetails(!showBasicDetails)}
              >
                <h4 className="text-start">Basic Details </h4>
                <div className="icon-detail-heading">
                  {showBasicDetails ? (
                    <MdOutlineArrowDropDown />
                  ) : (
                    <MdKeyboardArrowRight />
                  )}
                </div>
              </div>
              {showBasicDetails ? <BasicDetails startupUserData={startupUserData} /> : null}
            </div>

            <div className="details-dropdown ">
              <div
                className="basic_details_heading "
                onClick={() => setShowFinancialDetails(!showFinancialDetails)}
              >
                <h4 className="text-start">Financial Details </h4>
                <div className="icon-detail-heading">
                  {showFinancialDetails ? (
                    <MdOutlineArrowDropDown />
                  ) : (
                    <MdKeyboardArrowRight />
                  )}
                </div>
              </div>
              {showFinancialDetails ? <FinancialDetails startupUserData={startupUserData}/> : null}
            </div>

            <div className="details-dropdown ">
              <div
                className="basic_details_heading "
                onClick={() => setShowDocumentDetails(!showDocumentDetails)}
              >
                <h4 className="text-start">Documents Details </h4>
                <div className="icon-detail-heading">
                  {showDocumentDetails ? (
                    <MdOutlineArrowDropDown />
                  ) : (
                    <MdKeyboardArrowRight />
                  )}
                </div>
              </div>
              {showDocumentDetails ? <DocumentsDetails startupUserData={startupUserData} /> : null}
            </div>
          </div>
        )}
      </div>

      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getuserDataRedux: state.user.data,
    // updateScreen:state.update.data,
    getIsLodaerRedux: state.isLoader.isLoader,
    getSingleStartupIdRedux: state.singleStartupId.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoaderRedux: (parameter) => {
      dispatch(setLoader(parameter));
    },
    userDataRedux: parameter => {
      dispatch(setUserData(parameter));
    },
    setSingleStartupIdRedux: (parameter) => {
      dispatch(setSingleStartupId(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
