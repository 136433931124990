import { UPDATE_SCREEN } from "../type"

const initialState = {
    data: false
}

const updateScreenReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SCREEN: return {
            ...state,
            data: action.payload
        }
        default: return state
    }
}

export default updateScreenReducer;