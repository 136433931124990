
import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyA-84NKJ7AS_yrVlJ3xCqygG-WEtczsLMM",
  authDomain: "vinest-fb872.firebaseapp.com",
  projectId: "vinest-fb872",
  storageBucket: "vinest-fb872.appspot.com",
  messagingSenderId: "906647626445",
  appId: "1:906647626445:web:f5de767c67a2491b52ff84",
  measurementId: "G-FXGCTQ9P1H"
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app)