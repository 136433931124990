import React,{useEffect} from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'


function Blog() {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
  return (
    <>
    <NavBar/>
     <div className="container">
        <h4 className='text-start' >Blog</h4>
         <div className='py-4 text-start'>
             <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis architecto sequi quibusdam eius quisquam deleniti, cum excepturi? Dicta odio sint aspernatur. Itaque laborum veniam laboriosam facilis maxime mollitia voluptatem consectetur rerum laudantium vero, dolorem blanditiis reiciendis ipsa? Ad, quis aperiam? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis architecto sequi quibusdam eius quisquam deleniti, cum excepturi? Dicta odio sint aspernatur. Itaque laborum veniam laboriosam facilis maxime mollitia voluptatem consectetur rerum laudantium vero, dolorem blanditiis reiciendis ipsa? Ad, quis aperiam</span>
         </div>
         <div className='py-4 text-start'>
             <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis architecto sequi quibusdam eius quisquam deleniti, cum excepturi? Dicta odio sint aspernatur. Itaque laborum veniam laboriosam Itaque laborum veniam laboriosam facilis maxime mollitia voluptatem consectetur rerum laudantium vero, dolorem blanditiis reiciendis ipsa? Ad, quis aperiam?</span>
         </div>
         <div className='py-4 text-start'>
             <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis architecto sequi quibusdam eius quisquam deleniti, cum excepturi? Dicta odio sint aspernatur. Itaque laborum veniam laboriosam dolor sit amet consectetur, adipisicing elit. Corporis architecto sequi quibusdam eius quisquam deleniti, cum excepturi? Dicta odio sint aspernatur. Itaque laborum veniam facilis maxime mollitia voluptatem consectetur rerum laudantium vero, dolorem blanditiis reiciendis ipsa? Ad, quis aperiam?</span>
         </div>
         <div className='py-4 text-start'>
             <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam assumenda molestias officia libero vitae aspernatur odit, aperiam minima omnis eum fugiat dolore adipisci ratione at quo rerum eos, velit quasi quas ullam, vel laboriosam. Nulla, dignissimos explicabo? Praesentium voluptatibus aliquid quisquam voluptatum temporibus, repellendus rerum officia qui sapiente nisi? Quaerat, fuga odit. Eos ipsa aut numquam fuga quidem, aspernatur esse?</span>
         </div>

         <div className='py-4 text-start'>
             <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugit inventore qui iste ad deserunt labore rem saepe, omnis quis sit maxime consequatur explicabo aperiam molestias reiciendis aut officiis soluta doloremque, eveniet temporibus quam? Distinctio iste assumenda esse ut corrupti, fugiat consequatur harum, delectus eius aperiam iure totam ullam! Enim expedita dolorem blanditiis, saepe consectetur officia modi velit ea aliquid explicabo iusto maiores esse, corrupti obcaecati facilis assumenda! Porro distinctio necessitatibus, blanditiis officia vel numquam sit libero fugiat voluptates facilis!</span>
         </div>
         
     </div>
     <Footer/>
    </>
  )
}

export default Blog
