import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import {AiFillLinkedin} from 'react-icons/ai'
import { BsTwitter } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "../css/footer.css";

function Footer() {
  const navigation=useNavigate()
  return (
    <div className="footer">
      <div className="container">
        <div className="row border-bottom pb-4">
          <div className="col-md-4">
            <div className="footer-first">
              <img src="/img/logo3.png" alt="Image" />
              <p>
              Vinest is providing you a gateway to all your fund needs
              </p>
              <div className="footer-icon">
                <a href="https://www.linkedin.com/company/vinest-fund/" target="blank"> <AiFillLinkedin  /></a>
             
                <a href="https://instagram.com/vinestfund?igshid=OGQ5ZDc2ODk2ZA==" target="blank" ><AiFillInstagram/></a>
                <BsTwitter />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-center">
                    <h3>Company</h3>
                    <ul>
                      <li onClick={()=>navigation('/')} >Home</li>
                      <li onClick={()=>{navigation('/about')}} >About Us</li>
                      {/* <li onClick={()=>navigation('/blog')} >Blog</li> */}
                      <li onClick={()=>navigation('/contact')} >Contact</li>
                      {/* <li onClick={()=>navigation('/privacy')} >Privacy Policy</li>
                      <li onClick={()=>navigation('/term')}>Terms & Condition</li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-center">
                    <h3>Products</h3>
                    <ul>
                      <li onClick={()=>navigation('/startup-login')}>For Start-Ups</li>
                      <li onClick={()=>navigation('/investor-login')} >For Investors</li>
                      <li onClick={()=>navigation('/partner-login')}>For Partners</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-center">
              <h2>Contact Us</h2>
              <p>Nirmal Tower Barakhamba Rd, New Delhi, Delhi 110001</p>
              <p>Contact : +91 9599912022, +91 9953966893</p>
              <p className="footer-email" >Email :<span> pushkar@vinest.in, shubham@vinest.in</span></p>
            </div>
          </div>
        </div>
        <div className="row">
           <div className="col-md-3 d-flex align-items-center">
              <div className="footer-bottom-first">
                  <p>Privacy Policy</p>
                  <p onClick={()=>navigation('/terms-conditions')} >Terms & Conditions</p>
              </div>
           </div>
           <div className="col-md-9 d-flex align-items-center justify-content-end">
            <p className="copyright" >CopyRights</p>
           </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
