import { USER_DATA, SLIDER_IMAGES, UPDATE_SCREEN, CATEGORY_ID, SINGLE_STARTUP_ID, LOADER_STATE} from "./type";


export const setUserData = (parameter)=>{
    return {
        type: USER_DATA,
        payload:parameter
    }  
}

export const setSliderImages= (parameter)=>{
    return {
        type: SLIDER_IMAGES,
        payload:parameter
    }  
}
export const setUpdateScreen= (parameter)=>{
    return {
        type: UPDATE_SCREEN,
        payload:parameter
    }  
}

export const setCategoryId= (parameter)=>{
    return {
        type: CATEGORY_ID,
        payload:parameter
    }  
}

export const setSingleStartupId= (parameter)=>{
    return {
        type: SINGLE_STARTUP_ID,
        payload:parameter
    }  
}

export const setLoader= (parameter)=>{
    return {
        type: LOADER_STATE,
        payload:parameter
    }  
}