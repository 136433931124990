import React, { useState } from "react";
import "../css/partnerbelow.css";

function PartnerBelow() {
  const [onHover, setOnHover] = useState(null);
  return (
    <div className="partner-below">
      <h4 className="py-4">
        Join our Growth Story <span>and Become Our Partner</span>
      </h4>
      <div className="d-flex justify-content-between container">
        <div className="row justify-content-between p-2 p-sm-0">
          <div className="col-md-1"></div>
          <div
            onMouseEnter={() => {
              setOnHover("first");
            }}
            onMouseLeave={() => setOnHover(null)}
            className="partner-below-section col-md-2"
          >
            {onHover === "first" ? (
              <img
                className="white-section-image"
                src="/img/login-key8.png"
                alt="image"
              />
            ) : (
              <img
                className="dark-section-image"
                src="/img/login-key.png"
                alt="image"
              />
            )}
            <h5>Sign up for Partner program</h5>
          </div>
          <div
            onMouseEnter={() => {
              setOnHover("second");
            }}
            onMouseLeave={() => setOnHover(null)}
            className="partner-below-section col-md-2"
          >
            {onHover === "second" ? (
              <img
                className="white-section-image"
                src="/img/login-key6.png"
                alt="image"
              />
            ) : (
              <img
                className="dark-section-image"
                src="/img/login-key1.png"
                alt="image"
              />
            )}
            <h5>Refer client to Vinest </h5>
          </div>
          <div
            onMouseEnter={() => {
              setOnHover("third");
            }}
            onMouseLeave={() => setOnHover(null)}
            className="partner-below-section col-md-2"
          >
            {onHover === "third" ? (
              <img
                className="white-section-image"
                src="/img/login-key9.png"
                alt="image"
              />
            ) : (
              <img
                className="dark-section-image"
                src="/img/login-key2.png"
                alt="image"
              />
            )}
            <h5>Earn incentive on each successful referral </h5>
          </div>
          <div
            onMouseEnter={() => {
              setOnHover("forth");
            }}
            onMouseLeave={() => setOnHover(null)}
            className="partner-below-section col-md-2"
          >
            {onHover === "forth" ? (
              <img
                className="white-section-image"
                src="/img/login-key7.png"
                alt="image"
              />
            ) : (
              <img
                className="dark-section-image"
                src="/img/login-key3.png"
                alt="image"
              />
            )}
            <h5>
              Create a pool of happy and loyal clients by fulfilling their
              requirement{" "}
            </h5>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </div>
  );
}

export default PartnerBelow;
