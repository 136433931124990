import React,{useEffect} from "react";
import Typed from "react-typed";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "../css/banner.css";

function Banner({userData,getUpdateScreenRedux}) {
  const navigation = useNavigate()

  const onHandleSignup=()=>{
    if(userData?.data?.user?.role==='4'){
      navigation('/dashboard')
    }else if(userData?.data?.user?.role==='1'){
      navigation('/profile')
    }else{
      navigation('/startup-register')
    }
  }

  useEffect(()=>{
  
  },[getUpdateScreenRedux])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="d-flex flex-column justify-content-center">
            <div className="main-banner-class">
              <h1 className="first">Empowering The Next Wave of Business Leaders</h1>
              <h2 className="second">
                 Your Gateway To All Your Fund Needs
              </h2>
              <h1 className="typer-text">
                We Fund&nbsp;
                <Typed
                  style={{ color: "#343C5C" }}
                  strings={[
                    "Gaming Companies",
                    "Software Companies",
                    "D2C Companies",
                    "IT Services Companies",
                    "Manufacturer Companies",
                  ]}
                  typeSpeed={40}
                  backSpeed={7}
                  loop
                />
              </h1>
              <h3 className="forth">
                India’s Largest Platform To Transform Your Funding Future
              </h3>
              <div className="d-flex justify-content-center my-5">
                  <a
                    href="https://vinest.aicatech.in/"
                    target="_black"
                    className="register-btn"
                  >
                    GET FUNDED
                  </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



const mapStateToProps = state => {
  return {
    userData:state.user.data,
    getUpdateScreenRedux:state.update.data
  };
};
const mapDispatchToProps = dispatch => {
  return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);


