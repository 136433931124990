import * as React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom/dist";




export default function App({tableData}) {
  const navigation =useNavigate()

  const columns = [
    {
      field: "company_name",
      headerName: "Company",
      width: 200,
      headerClassName: "column-style",
    },
    {
      field: "founder_email",
      headerName: "Founder",
      width: 120,
      headerClassName: "column-style",
    },
    {
      field: "investment_type",
      headerName: "Inv type",
      width: 120,
      height: 100,
      headerClassName: "column-style",
      renderCell: (params) => {
        return (
          <div className="dashboard-table-invest-type ">
            <span>{params.row.investment_type}</span>
          </div>
        );
      },
    },
    {
      field: "categoryName",
      headerName: "Sector",
      width: 100,
      headerClassName: "column-style",
    },
    {
      field: "capital",
      headerName: "Cap Req",
      width: 120,
      headerClassName: "column-style",
      renderCell: (params) => {
        return (
          <div className="dashboard-table-capital-require ">
            <span>{params.row.capital}</span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 120,
      headerClassName: "column-style",
      renderCell: (params) => {
        return (
          <>
            <button onClick={()=>{console.log(params.row.id);navigation(`/edit-details/${params.row.id}`)}} className="view-button">View</button>
          </>
        );
      },
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        "& .column-style": {
          fontSize: 13,
          fontWeight: 800,
          letterSpacing: "1px",
          textAlign: "center",
        },
      }}
    >
      <DataGrid
          initialState={{
            ...tableData?.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10]}
        className="p-3"
        rows={tableData?tableData:[]}
        columns={columns}
      />
    </Box>
  );
}
