import React,{useEffect, useState} from "react";
import { RevenueFetchApi } from "../Apis";
import "../cssdashboard/index.css";

function DashboardList() {
const [data, setData] = useState()

  useEffect(()=>{
    FetchData()
  },[])

  const FetchData =()=>{
    var token = localStorage.getItem("VinestToken");
    RevenueFetchApi(token, value=>{
      setData(value)
    })
  }

console.log(data,'data')

  return (
    <div>
      <h3 className="dashboard-listing-title text-start" >Yours :</h3>
          <div className="list-main">
            <div className="list-bottom d-flex justify-content-between align-items-center">
              <h5>Average Revenue</h5>
              <p>{data?.payAmountYour} $</p>
            </div>
          </div>
          <div className="list-main">
            <div className="list-bottom d-flex justify-content-between align-items-center">
              <h5>Average Deal</h5>
              <p>{data?.totalDealYour} $</p>
            </div>
          </div>
          <div className="list-main">
            <div className="list-bottom d-flex justify-content-between align-items-center">
              <h5>Avearge Deal Price</h5>
              <p>{data?.totalAverageDealYour} $</p>
            </div>
          </div>
        <h3 className="dashboard-listing-title text-start" >Peer :</h3>
     
          <div className="list-main">
            <div className="list-bottom d-flex justify-content-between align-items-center">
              <h5>Average Revenue</h5>
              <p>{data?.payAmountPeer} $</p>
            </div>
          </div>
          <div className="list-main">
            <div className="list-bottom d-flex justify-content-between align-items-center">
              <h5>Average Deal </h5>
              <p>{data?.totalDealPeer} $</p>
            </div>
          </div>
          <div className="list-main">
            <div className="list-bottom d-flex justify-content-between align-items-center">
              <h5>Average Deal Price</h5>
              <p>{data?.totalAverageDealPeer} $</p>
            </div>
          </div>
    </div>
  );
}

export default DashboardList;
