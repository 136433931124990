import React from "react";
import "../css/entrepreneur.css";

function Entrepreneur() {
  return (
    <div className="main-enterprenuer">
      <div className="container">
        <div className="column">
            <div className="enter-heading">
                <h1>Why <span className="entrepreneur-v" >V<span>!</span>nest</span> 
                    {/* <img src="/img/white-logo.png" alt="Image" /> */}
                     
                </h1>
                <p>From funding challenges to funding success - We've got you covered</p>
            </div>
          <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <div className="enter-card">
                        <img src="/Icons/Rocket.png" alt="Image" />
                        <h1>Get Funded</h1>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="enter-card">
                        <img src="/Icons/Service.png" alt="Image" />
                        <h1>Invest With Us</h1>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="enter-card">
                        <img src="/Icons/Increase.png" alt="Image" />
                        <h1>Grow With Us</h1>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="enter-card">
                        <img src="/Icons/Coin.png" alt="Image" />
                        <h1>Startup Support Services</h1>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Entrepreneur;
