import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import "../css/modalcss/changepassword.css";


const theme = createTheme({
  palette: {
    neutral: {
      main: "#FE5902",
      contrastText: "#fff",
    },
  },
});



function ChangePasswordModal({ openModal, setOpenModal }) {
  const handleClose = () => setOpenModal(false);
  return (
    <div>
      <Modal
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        show={openModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
           <div className="my-3">
             <input type="text" placeholder="Current Password*" className="form-control" />
           </div>

           <div className="my-3">
             <input type="text" placeholder="New Password*" className="form-control" />
           </div>

           <div className="my-3">
             <input type="text" placeholder="Confirm New Password*" className="form-control" />
           </div>
           <div className="my-3">
             <button className="change-password-button">Save Change</button>
           </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangePasswordModal;
