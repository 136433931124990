import React,{useState} from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import TuneIcon from "@mui/icons-material/Tune";
import Form from "react-bootstrap/Form";
import FilterModal from "../modals/FilterModal";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import { setLoader } from "../Redux";
import "../cssdashboard/index.css";

function DashboardFIlter({setSelectType,getIsLodaerRedux,setIsLoaderRedux,lengthData, handleChangeSearch}) {
  const navigation= useNavigate()
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [selectCategory, setSelectCategory] = useState('DEALS ONGOING')
  return (
    <div>
      <FilterModal setOpenFilterModal={setOpenFilterModal} openFilterModal={openFilterModal} />
      <div className="dashboard-filter mt-3">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-6 ">
              <div className="filter-title">
                <ul>
                   <li onClick={()=>{setSelectCategory('DEALS ONGOING');setSelectType(1);setIsLoaderRedux(true)}} className={selectCategory==='DEALS ONGOING'?"selected-button-type":"unselected-button-type"} >Deals Ongoning({lengthData?.ongoingTotal})</li>
                   <li onClick={()=>{setSelectCategory('DEALS FINANCED');setSelectType(2);setIsLoaderRedux(true)}} className={selectCategory==='DEALS FINANCED'?"selected-button-type":"unselected-button-type"} >Deals Financed({lengthData?.dealFinancialTotal})</li>
                   <li onClick={()=>{setSelectCategory('WAITLIST');setSelectType(3);setIsLoaderRedux(true)}} className={selectCategory==='WAITLIST'?"selected-button-type":"unselected-button-type"} >Waitlist({lengthData?.waitListTotal})</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center m-0 p-0 ">
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-md-4"></div>

                  <div className="col-md-4">
                    <div className="filter-btn">
                      <button onClick={()=>navigation('/add-startup')} >
                        Add Start-Up <AiOutlinePlusCircle />
                      </button>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <InputGroup className="">
                      <Form.Control
                        placeholder="Search..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e)=>handleChangeSearch(e)}
                      />
                      <InputGroup.Text className="search-icon-filter">
                        <SearchIcon />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>

                  {/* <div className="col-md-4"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getuserDataRedux: state.user.data,
    getIsLodaerRedux: state.isLoader.isLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoaderRedux: (parameter) => {
      dispatch(setLoader(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFIlter);

