import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import NavBar from "../components/NavBar";
import { setUserData } from "./../Redux/actions";
import Footer from "../components/Footer";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import LoginDetailsPage from "../components/LoginDetailsPage";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import GoogleLogin from "./GoogleLogin";
import "../css/register.css";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registrationApi } from "../Apis";
import PartnerWelcome from "./PartnerWelcome";
import PartnerBelow from "./PartnerBelow";
import Swal from "sweetalert2";

let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
var token = localStorage.getItem("VinestToken");
function RegisterPartner({ userDataRedux, getuserDataRedux }) {
  const navigation = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [isPasswordShow, setisPasswordShow] = useState(false);
  const [isCPasswordShow, setisCPasswordShow] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const googleLogin = () => {
    GoogleLogin(4, navigation, (data) => {
      userDataRedux(data);
    });
  };

  const handleClickRegistration = () => {
    if (name === "" || email === "" || password === "") {
      toast.error("Fields Should Not Be Empty", {
        theme: "colored",
      });
      setEmailError(true);
      setNameError(true);
      setPasswordError(true);
    } else if (password !== cpassword) {
      toast.error("Password and Confirm Password Does Not Match", {
        theme: "colored",
      });
      setPasswordError(true);
    } else if (emailreg.test(email) === false) {
      toast.error("Email Format is not correct it Contain @ & .com", {
        theme: "colored",
      });
      setEmailError(true);
    } else if (passReg.test(password) === false) {
      toast.error(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters",
        {
          theme: "colored",
        }
      );
      setPasswordError(true);
    } else {
      registrationApi(name, email, password, 4, "", null, (data) => {
        let Userdata = data?.data?.user;
        if (data.status === "success") {
          Swal.fire({
            title: "Success",
            text: data.message,
            icon: "success",
            confirmButtonColor: "#343C5C",
          });
          if (Userdata.role === 4) {
            navigation("/partner-login");
          } else {
            navigation("/startup-login");
          }
        } else if (data.status === "failed") {
          toast.error(data.message, {
            theme: "colored",
          });
        }
      });
    }
  };

  // useEffect(()=>{
  //   if(token && getuserDataRedux?.data?.user?.role==='1'){
  //     navigation('/profile')
  //   }
  //   if(token && getuserDataRedux?.data?.user?.role==='4'){
  //     navigation('/Dashboard')
  //   }
  //   },[])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ToastContainer />
      <TopBar />
      <div className="upper-login">
        <NavBar />
      </div>
      <div className="startup-register">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-12 d-flex justify-content-center text-center">
              <div className="row">
                <PartnerWelcome/>
              </div>
            </div>

            
          </div>
        </div>
      </div>
      {/* <Partner/> */}
      <LoginDetailsPage />
      <PartnerBelow/>
  
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getuserDataRedux: state.user.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userDataRedux: (parameter) => {
      dispatch(setUserData(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPartner);
